<template>
  <div class="image-box blue-grey darken-2"
       :style="{position: 'relative', height, width: `calc(${height} / 2)`}">
    <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
         height="100%"
         style="fill-rule:nonzero;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;" xml:space="preserve"
         width="100%" version="1.1" :viewBox="page">
      <defs>
        <image id="Image" :href="img" overflow="visible"/>
      </defs>
      <g id="图层 1">
        <use opacity="1" xlink:href="#Image" transform="matrix(1 0 0 1 0 0)"/>
      </g>
      <g @click="selectBodyPart" id="右足" data-name="右足" name="右足">
<path class="cls-1"
      d="M713.739+951.289C715.933+956.794+717.044+964.669+714.216+971.269C711.696+977.151+706.558+982.692+703.261+985.89C699.963+989.088+700.951+1000.44+707.554+1000.32C718.182+1000.13+723.732+1000.74+731.562+1000.74C741.106+1000.74+751.25+1001.7+754.611+994.318C757.564+987.83+757.252+977.512+756.291+972.29C755.124+965.945+755+956.029+753.888+947.895C749.478+945.867+738.179+944.813+733.814+945.287C729.801+945.723+718.209+947.496+713.739+951.289Z"/>
</g>
      <g @click="selectBodyPart" id="左足" data-name="左足" name="左足">
<path class="cls-1"
      d="M827.134+952.773C827.678+955.049+830.639+966.39+835.894+971.284C841.148+976.179+851.945+984.025+855.688+986.688C859.431+989.351+864.757+1000.29+853.744+1000C842.732+999.716+829.488+1000.15+821.066+999.356C812.645+998.564+792.309+1002.76+787.285+993.616C783.852+987.37+783.951+980.921+785.797+975.963C788.048+969.917+789.228+962.056+789.097+953.848C793.15+951.177+803.689+949.365+808.075+949.174C812.108+948.999+822.142+949.699+827.134+952.773Z"/>
</g>
      <g @click="selectBodyPart" id="右踝前部" data-name="右踝前部" name="右踝前部">
<path class="cls-1"
      d="M709.939+905.177C710.521+908.332+712.801+924.626+713.204+928.705C713.607+932.784+714.493+946.833+713.855+951.199C717.751+948.67+724.798+946.954+730.928+945.698C737.058+944.442+748.003+945.573+753.971+947.895C755.233+938.543+752.667+934.547+751.533+931.51C750.4+928.474+748.397+922.675+747.66+918.793C746.923+914.911+745.636+905.703+745.201+900.836C739.821+901.725+715.556+904.947+709.939+905.177Z"/>
</g>
      <g @click="selectBodyPart" id="左踝前部" data-name="左踝前部" name="左踝前部">
<path class="cls-1"
      d="M828.099+906.707C827.874+909.908+826.639+925.46+826.695+929.558C826.751+933.657+826.022+948.422+827.144+952.689C822.99+950.612+817.881+949.714+811.649+949.152C805.417+948.591+794.806+950.78+789.135+953.756C786.833+944.605+788.798+940.506+789.584+937.362C790.37+934.218+791.711+928.231+792.008+924.291C792.306+920.351+791.83+911.367+791.718+906.483C797.164+906.764+823.158+907.718+828.099+906.707Z"/>
</g>
      <g @click="selectBodyPart" id="右小腿前部" data-name="右小腿前部" name="右小腿前部">
<path class="cls-1"
      d="M684.874+766.386C684.059+769.608+681.814+809.501+686.15+823.472C688.969+832.557+696.572+856.902+699.097+864.437C702.024+873.168+706.393+891.933+709.815+905.127C715.738+904.693+738.103+902.319+745.149+900.836C745.045+894.339+746.045+856.281+746.463+849.586C746.823+843.827+749.46+821.2+748.68+811.602C747.9+802.005+745.247+771.533+742.651+763.998C732.261+766.008+692.383+766.479+684.874+766.386Z"/>
</g>
      <g @click="selectBodyPart" id="左小腿前部" data-name="左小腿前部" name="左小腿前部">
<path class="cls-1"
      d="M839.637+762.465C840.811+765.574+844.116+812.663+842.785+822.082C841.454+831.501+835.931+857.514+834.271+865.285C832.347+874.291+830.121+893.428+828.209+906.924C822.274+907.16+798.884+907.16+791.717+906.481C790.59+901.464+785.822+859.623+784.389+853.07C782.955+846.518+779.356+828+779.049+818.376C778.742+808.752+780.706+771.032+782.437+763.253C794.071+764.646+830.791+763.192+839.637+762.465Z"/>
</g>
      <g @click="selectBodyPart" id="右膝" data-name="右膝" name="右膝">
<path class="cls-1"
      d="M750.151+683.232C750.839+693.673+748.067+710.442+747.254+716.221C746.287+723.098+742.263+738.665+741.939+744.6C741.569+751.38+741.56+758.552+742.604+764.057C735.541+765.902+691.991+766.816+684.993+766.429C684.955+760.253+688.155+741.539+687.745+733.305C687.335+725.072+685.96+699.165+684.684+686.172C700.935+684.005+742.473+682.045+750.151+683.232Z"/>
</g>
      <g @click="selectBodyPart" id="左膝" data-name="左膝" name="左膝">
<path class="cls-1"
      d="M771.35+682.946C771.182+693.409+773.924+717.633+775.023+723.365C776.331+730.185+779.153+739.429+781.315+746.097C783.478+752.765+782.937+758.712+782.307+763.128C789.452+764.62+832.557+763.141+839.527+762.407C837.725+756.099+835.98+743.983+835.98+735.74C835.98+727.496+836.955+696.098+838.573+682.803C822.234+681.446+778.959+681.38+771.35+682.946Z"/>
</g>
      <g @click="selectBodyPart" id="右大腿前部" data-name="右大腿前部" name="右大腿前部">
<path class="cls-1"
      d="M661.433+535.377C660.473+549.421+665.978+588.944+667.249+598.956C669.066+613.262+683.493+668.88+684.517+686.118C694.246+684.508+734.777+682.047+750.01+683.255C749.483+675.332+749.739+632.187+751.339+620.041C752.939+607.895+755.789+588.564+755.836+581.772C755.889+574.033+754.199+546.682+753.269+535.236C742.699+535.209+678.122+538.648+661.433+535.377Z"/>
</g>
      <g @click="selectBodyPart" id="左大腿前部" data-name="左大腿前部" name="左大腿前部">
<path class="cls-1"
      d="M854.9+529.68C856.785+544.365+855.209+586.057+854.479+596.648C853.437+611.781+841.332+677.458+838.514+682.885C828.225+681.355+787.401+681.163+771.218+682.964C771.288+674.612+768.401+633.246+765.956+620.594C763.511+607.941+760.622+587.653+760.153+580.525C759.618+572.404+759.084+547.184+759.364+535.109C768.381+535.216+837.624+532.35+854.9+529.68Z"/>
</g>
      <g @click="selectBodyPart" id="阴部" data-name="阴部" name="阴部">
<path class="cls-1"
      d="M789.65+491.023C789.65+491.023+764.55+526.428+762.92+528.86C761.252+529.837+750.411+529.323+749.79+527.405C748.68+523.973+734.841+493.75+734.841+493.75L751.007+486.707C751.007+486.707+773.935+487.724+774.775+487.915C775.786+488.145+789.65+491.023+789.65+491.023Z"/>
</g>
      <g @click="selectBodyPart" id="小腹" data-name="小腹" name="小腹">
<path class="cls-1"
      d="M784.368+428.485C783.373+434.79+776.504+479.433+775.058+487.973C771.621+487.544+755.358+486.979+750.749+486.799C749.71+481.557+747.105+438.342+746.343+428.125C752.874+428.565+780.601+428.485+784.368+428.485Z"/>
</g>
      <g @click="selectBodyPart" id="腹部" data-name="腹部" name="腹部">
<path class="cls-1"
      d="M794.098+349.159C800.226+351.581+812.34+354.162+815.211+354.935C815.237+359.166+815.423+367.351+815.116+369.42C812.38+387.901+812.209+419.584+811.708+428.497C808.644+427.928+788.59+428.122+784.331+428.482C781.306+428.738+749.063+428.34+746.271+428.074C742.75+427.739+722.878+427.907+718.895+428.032C717.209+408.55+717.15+377.713+718.074+366.201C718.087+361.187+717.722+356.377+717.885+353.609C724.785+351.932+739.626+348.275+742.078+347.304C746.157+347.365+790.641+349.053+794.098+349.159Z"/>
</g>
      <g @click="selectBodyPart" id="胃脘部" data-name="胃脘部" name="胃脘部">
<path class="cls-1"
      d="M777.469+264.878C778.722+271.979+787.987+283.181+792.658+285.649C791.918+301.234+792.497+330.109+794.167+349.06C785.948+348.969+753.91+347.646+742.275+347.485C743.244+324.938+743.92+291.441+743.868+280.206C747.862+275.831+752.085+269.218+753.776+264.853C756.788+264.853+774.849+264.954+777.469+264.878Z"/>
</g>
      <g @click="selectBodyPart" id="胸骨" data-name="胸骨" name="胸骨">
<path class="cls-1"
      d="M782.267+189.08C780.552+192.78+777.261+218.675+777.026+222.964C776.79+227.252+774.025+256.195+777.403+264.859C773.518+264.953+759.241+264.966+753.712+264.879C755.652+260.594+756.81+255.673+756.639+248.88C756.797+240.266+755.973+230.778+755.819+225.226C755.849+218.155+752.283+197.407+749.175+188.751C751.723+189.217+760.682+192.037+765.89+191.655C769.38+191.816+779.631+189.624+782.267+189.08Z"/>
</g>
      <g @click="selectBodyPart" id="咽喉" data-name="咽喉" name="咽喉">
<path class="cls-1"
      d="M780.3+147.16C780.143+153.655+778.951+164.192+777.267+169.512C775.889+174.496+771.966+187.803+770.351+191.368C768.467+191.53+761.152+191.868+760.028+191.181C758.296+187.417+754.53+172.596+753.992+170.282C752.735+165.958+750.436+151.714+750.655+146.049C754.45+148.457+764.005+151.937+766.48+151.937C768.956+151.937+778.686+148.613+780.3+147.16Z"/>
</g>
      <g @click="selectBodyPart" id="右少腹" data-name="右少腹" name="右少腹">
<path class="cls-1"
      d="M718.861+428.059C718.903+432.861+719.647+443.96+721.733+452.206C724.207+464.333+729.236+482.807+734.91+493.826C739.798+491.63+746.428+488.964+750.712+486.811C749.507+480.504+747.037+433.006+746.256+428.006C741.653+427.961+722.686+427.67+718.861+428.059Z"/>
</g>
      <g @click="selectBodyPart" id="左少腹" data-name="左少腹" name="左少腹">
<path class="cls-1"
      d="M811.633+428.35C811.591+433.151+810.67+443.58+808.584+451.826C806.11+463.953+795.727+480.544+789.616+491.047C785.098+490.046+775.16+487.945+775.16+487.945C775.16+487.945+783.604+433.402+784.386+428.401C788.988+428.357+807.808+427.961+811.633+428.35Z"/>
</g>
      <g @click="selectBodyPart" id="右上腹" data-name="右上腹" name="右上腹">
<path class="cls-1"
      d="M731.272+287.676C727.931+289.792+724.963+293.413+722.993+296.647C720.118+301.365+717.912+313.266+717.98+324.141C718.048+335.016+717.791+347.31+717.816+353.441C721.894+352.897+735.194+349.225+742.125+347.391C742.391+340.073+743.559+315.596+743.523+311.795C743.471+306.391+744.177+285.275+743.779+280.136C741.273+283.379+734.482+287.44+731.272+287.676Z"/>
</g>
      <g @click="selectBodyPart" id="左上腹" data-name="左上腹" name="左上腹">
<path class="cls-1"
      d="M805.096+289.456C807.067+291.291+810.575+295.48+812.212+298.895C814.298+303.244+815.518+313.875+815.45+324.75C815.382+335.625+815.189+348.633+815.164+354.763C811.086+354.219+797.522+350.498+794.243+349.171C793.098+343.839+792.437+315.994+792.473+312.194C792.524+306.79+792.139+290.831+792.537+285.692C794.636+287.502+801.886+289.221+805.096+289.456Z"/>
</g>
      <g @click="selectBodyPart" id="右胯" data-name="右胯" name="右胯">
<path class="cls-1"
      d="M683.384+414.414C683.384+414.414+671.673+438.235+672.125+464.735C667.457+476.028+662.073+527.246+661.256+535.178C674.49+539.243+741.411+534.901+753.13+535.3C747.962+523.173+738.788+501.739+734.644+493.388C732.147+488.354+723.427+465.557+720.414+444.299C714.543+441.648+709.989+438.598+705.015+435.512C697.773+431.02+692.559+427.766+683.384+414.414Z"/>
</g>
      <g @click="selectBodyPart" id="左胯" data-name="左胯" name="左胯">
<path class="cls-1"
      d="M838.497+426.468C838.497+426.468+840.944+461.692+842.782+470.13C844.914+479.915+855.086+521.573+854.803+529.542C841.135+531.746+771.007+535.917+759.345+534.698C765.999+523.25+783.063+500.645+788.317+492.944C791.485+488.302+801.325+471.836+806.065+460.124C807.922+455.537+809.544+446.059+810.006+445.185C821.612+441.791+833.471+435.873+838.497+426.468Z"/>
</g>
      <g @click="selectBodyPart" id="右腰" data-name="右腰" name="右腰">
<path class="cls-1"
      d="M687.149+349.353C687.149+349.353+691.547+369.787+688.856+377.38C685.353+387.262+683.727+402.398+683.351+414.282C686.479+418.91+693.585+428.487+699.398+431.95C705.424+435.357+714.27+442.187+720.378+444.053C718.542+436.478+717.202+393.804+717.39+388.852C717.598+383.388+718.142+366.224+718.142+366.224L687.149+349.353Z"/>
</g>
      <g @click="selectBodyPart" id="左腰" data-name="左腰" name="左腰">
<path class="cls-1"
      d="M838.122+356.092C838.122+356.092+838.104+381.827+838.914+389.842C839.725+397.857+840.39+418.846+838.483+426.156C837.434+430.173+831.686+435.227+825.873+438.69C819.847+442.096+816.114+443.352+810.006+445.219C811.842+437.644+812.752+393.267+813.177+388.512C813.664+383.066+815.158+369.239+815.158+369.239L838.122+356.092Z"/>
</g>
      <g @click="selectBodyPart" id="右胁肋" data-name="右胁肋" name="右胁肋">
<path class="cls-1"
      d="M678.35+272.674C674.957+278.346+669.396+297.791+668.643+303.06C670.483+309.082+676.918+323.647+680.143+330.576C684.074+339.023+685.814+344.575+687.175+349.312C690.207+351.226+709.536+361.274+718.039+366.062C717.972+361.054+717.676+331.136+717.839+327.002C718.464+311.124+718.791+295.615+731.193+287.741C724.025+289.055+709.356+288.686+700.953+285.959C696.383+283.895+683.154+279.057+678.35+272.674Z"/>
</g>
      <g @click="selectBodyPart" id="左胁助" data-name="左胁助" name="左胁助">
<path class="cls-1"
      d="M848.987+269.732C848.821+277.957+849.206+302.636+849.591+307.543C849.897+311.457+842.084+326.329+841.426+333.944C840.768+341.558+839.53+351.224+838.169+355.961C835.138+357.876+823.649+364.471+815.145+369.259C815.051+364.747+815.451+323.36+815.288+319.225C814.663+303.347+813.258+297.064+805.044+289.503C810.057+289.633+823.757+289.936+830.781+286.28C835.351+283.73+844.732+277.861+848.987+269.732Z"/>
</g>
      <g @click="selectBodyPart" id="右前胸" data-name="右前胸" name="右前胸">
<path class="cls-1"
      d="M663.92+227.94C668.19+231.833+676.589+268.644+678.421+272.858C679.964+276.407+693.622+283.197+699.409+285.28C709.386+288.872+729.492+290.318+737.369+285.348C740.516+283.362+743.819+280.406+746.703+276.799C751.036+271.378+755.931+261.961+756.407+254.682C757.165+243.079+755.866+225.528+755.366+218.964C754.964+213.68+751.286+194.126+749.289+188.847C745.4+187.81+743.222+187.239+735.739+185.462C719.815+187.435+706.892+191.558+701.36+193.246C695.828+194.934+681.266+199.545+676.861+205.478C672.924+209.591+663.909+223.058+663.92+227.94Z"/>
</g>
      <g @click="selectBodyPart" id="左前胸" data-name="左前胸" name="左前胸">
<path class="cls-1"
      d="M859.714+223.87C855.445+227.763+849.416+262.836+849.416+267.431C849.416+272.025+839.834+281.416+832.634+285.416C823.364+290.566+801.466+291.272+793.59+286.302C785.713+281.333+776.855+270.283+776.062+258.16C775.304+246.558+776.604+229.006+777.103+222.443C777.505+217.159+780.242+194.407+782.239+189.127C785.378+188.057+783.666+188.699+795.219+186.416C809.491+186.274+825.599+189.98+831.131+191.667C836.664+193.355+851.296+196.618+855.136+201.138C859.072+205.25+859.725+218.988+859.714+223.87Z"/>
</g>
      <g @click="selectBodyPart" id="右手掌" data-name="右手掌" name="右手掌">
<path class="cls-1"
      d="M583.932+477.195C578.932+480.385+575.693+484.472+573.776+487.479C571.86+490.487+568.199+499.026+568.275+504.972C568.311+507.837+558.755+516.726+556.633+518.005C554.511+519.283+553.69+526.275+560.672+525.899C567.653+525.524+564.353+525.672+565.038+524.544C565.722+523.417+564.045+539.453+563.909+542.912C563.772+546.371+561.554+570.993+562.855+573.249C564.155+575.504+569.732+572.57+572.264+571.894C574.797+571.217+573.977+574.112+573.544+578.443C572.996+583.923+582.755+590.798+584.007+580.249C585.314+569.241+584.134+582.102+588.9+582.056C595.096+581.996+595.381+571.29+596.202+569.786C597.024+568.282+602.839+571.769+605.988+562.86C608.867+554.716+613.064+534.481+616.678+529.437C620.016+524.778+619.867+505.343+619.388+499.929C617.524+492.83+616+483.669+616+483.669C616+483.669+600.638+482.415+597.763+482.264C594.888+482.114+589.373+479.62+583.932+477.195Z"/>
</g>
      <g @click="selectBodyPart" id="左手掌" data-name="左手掌" name="左手掌">
<path class="cls-1"
      d="M920.528+475.579C926.052+478.787+928.09+481.477+930.208+484.502C932.325+487.527+935.333+497.507+937.165+500.155C938.851+502.594+951.154+513.465+953.499+514.75C955.843+516.036+956.75+523.068+949.037+522.69C941.324+522.312+936.711+519.892+935.955+518.758C935.199+517.624+935.199+534.033+935.35+537.512C935.501+540.99+935.612+567.946+934.176+570.215C932.739+572.483+932.067+575.378+929.269+574.697C926.472+574.016+923.945+572.704+924.424+577.059C925.03+582.571+914.652+587.292+913.269+576.683C911.825+565.612+912.912+578.378+907.646+578.331C900.802+578.271+901.85+565.491+900.943+563.979C900.035+562.466+896.33+568.432+892.852+559.473C889.671+551.282+883.285+529.299+882.151+523.628C881.017+517.956+880.078+501.219+880.607+495.775C882.665+488.635+883.628+479.129+883.628+479.129C883.628+479.129+900.532+479.408+903.708+479.257C906.884+479.106+914.517+478.018+920.528+475.579Z"/>
</g>
      <g @click="selectBodyPart" id="右手腕内侧" data-name="右手腕内侧" name="右手腕内侧">
<path class="cls-1"
      d="M588.072+457.021C588.072+457.021+587.171+464.36+586.341+467.334C585.511+470.308+585.212+472.528+583.932+477.195C587.7+479.205+595.757+482.389+598.385+482.389C601.013+482.389+611.404+483.696+616.151+483.368C616.23+482.11+616.71+476.862+616.979+474.937C617.968+467.84+621.872+461.914+621.872+461.914L588.072+457.021Z"/>
</g>
      <g @click="selectBodyPart" id="左手腕内侧" data-name="左手腕内侧" name="左手腕内侧">
<path class="cls-1"
      d="M914.014+454.335C914.014+454.335+914.937+464.574+915.767+467.547C916.597+470.521+917.475+473.225+920.49+475.737C916.721+477.747+905.336+479.301+902.708+479.301C900.08+479.301+888.358+479.381+883.611+479.053C883.238+473.158+880.177+457.147+880.177+457.147L914.014+454.335Z"/>
</g>
      <g @click="selectBodyPart" id="右前臂内侧" data-name="右前臂内侧" name="右前臂内侧">
<path class="cls-1"
      d="M606.397+351.292C602.663+361.532+598.686+385.411+597.982+392.658C597.45+398.135+593.54+432.063+592.974+435.382C592.408+438.701+588.058+457.089+588.058+457.089L622.03+461.957C622.03+461.957+635.276+432.463+638.69+426.615C642.104+420.767+651.633+396.372+653.051+391.285C654.469+386.198+661.409+362.727+661.182+351.917C653.537+356.33+640.174+361.359+629.52+362.08C623.468+361.563+613.097+356.05+606.397+351.292Z"/>
</g>
      <g @click="selectBodyPart" id="左前臂内侧" data-name="左前臂内侧" name="左前臂内侧">
<path class="cls-1"
      d="M905.665+343.576C908.588+354.076+910.509+382.224+910.644+389.504C910.746+395.005+911.91+433.242+912.214+436.595C912.519+439.948+913.997+454.331+913.997+454.331L880.161+457.176C880.161+457.176+870.406+426.897+867.46+420.8C864.513+414.703+855.673+389.301+854.657+384.119C853.641+378.937+849.928+350.678+850.999+339.918C858.276+344.915+871.205+350.973+881.77+352.525C887.844+352.482+898.614+347.797+905.665+343.576Z"/>
</g>
      <g @click="selectBodyPart" id="右肘" data-name="右肘" name="右肘">
<path class="cls-1"
      d="M615.28+315.298C612.991+333.372+609.72+340.677+606.468+351.135C610.145+354.399+621.525+361.16+629.226+361.83C637.186+362.522+659.59+354.528+661.148+351.784C661.99+346.293+664.133+325.265+664.607+320.034C657.356+321.595+631.258+319.264+615.28+315.298Z"/>
</g>
      <g @click="selectBodyPart" id="左肘" data-name="左肘" name="左肘">
<path class="cls-1"
      d="M899.058+309.507C900.423+327.651+903.412+332.5+905.655+343.22C901.684+346.119+888.365+352.279+882.38+352.395C874.391+352.549+851.919+342.317+850.628+339.438C850.313+333.891+849.494+313.469+849.519+308.217C856.589+310.461+882.774+311.934+899.058+309.507Z"/>
</g>
      <g @click="selectBodyPart" id="右上臂" data-name="右上臂" name="右上臂">
<path class="cls-1"
      d="M631.291+269.409C628.147+265.973+625.858+255.331+625.344+249.954C619.851+259.336+617.792+275.641+617.048+284.737C616.421+292.407+615.348+311.311+615.327+315.267C617.955+315.88+632.805+318.893+637.791+319.246C641.246+319.49+662.812+321.452+664.89+320.001C666.094+313.359+672.532+283.532+678.177+272.826C676.588+267.014+667.941+233.492+663.963+227.711C657.752+233.971+649.634+242.823+646.162+248.838C643.415+253.186+635.413+264.6+631.291+269.409Z"/>
</g>
      <g @click="selectBodyPart" id="左上臂" data-name="左上臂" name="左上臂">
<path class="cls-1"
      d="M888.462+262.225C890.578+260.498+892.138+255.708+893.176+252.876C895.201+257.769+898.014+278.6+898.376+286.287C898.739+293.974+899.435+305.726+898.977+309.656C896.294+309.946+881.263+310.883+877.043+310.851C873.58+310.824+851.526+309.869+849.639+308.177C849.249+301.437+848.524+274.717+849.249+267.683C849.974+260.649+855.164+229.082+859.813+223.825C865.979+229.832+874.089+238.671+876.233+243.696C878.345+247.408+884.97+258.544+888.462+262.225Z"/>
</g>
      <g @click="selectBodyPart" id="右肩" data-name="右肩" name="右肩">
<path class="cls-1"
      d="M674.713+168.087C657.254+171.128+645.318+179.433+637.825+188.332C631.213+196.183+625.15+216.576+624.558+223.509C623.562+235.182+625.235+263.716+631.405+269.471C639.533+259.86+647.334+246.343+652.217+240.431C657.1+234.52+660.095+232.08+663.954+227.495C664.663+220.986+675.631+204.769+681.844+201.221C688.951+197.163+709.152+188.807+735.75+185.508C727.222+185.42+718.505+184.223+710.227+182.79C696.175+177.544+683.569+173.744+674.713+168.087Z"/>
</g>
      <g @click="selectBodyPart" id="左肩" data-name="左肩" name="左肩">
<path class="cls-1"
      d="M847.872+165.586C864.894+166.418+876.895+175.668+884.389+184.566C891+192.417+895.855+211.682+896.447+218.615C897.444+230.288+894.667+256.526+888.497+262.281C880.369+252.67+876.4+242.431+871.517+236.52C866.634+230.609+863.639+228.169+859.781+223.584C859.071+217.075+860.389+201.162+851.055+198.028C843.297+195.424+821.603+186.746+795.009+186.255C818.375+183.308+827.357+179.589+847.872+165.586Z"/>
</g>
      <g @click="selectBodyPart" id="右颈肩前部" data-name="右颈肩前部" name="右颈肩前部">
<path class="cls-1"
      d="M731.183+140.358C723.256+148.77+717.746+151.996+704.791+158.579C699.697+161.167+680.296+167.053+674.785+168.029C680.468+172.449+705.241+181.272+709.145+182.42C710.866+182.926+716.448+183.997+722.595+184.741C727.141+185.292+731.997+185.664+735.827+185.497C734.406+172.982+731.787+151.513+731.183+140.358Z"/>
</g>
      <g @click="selectBodyPart" id="左颈肩前部" data-name="左颈肩前部" name="左颈肩前部">
<path class="cls-1"
      d="M796.731+146.913C804.621+153.426+814.225+157.835+819.721+159.397C825.217+160.958+842.341+164.506+847.853+165.482C842.169+169.903+824.141+180.18+820.237+181.328C817.244+182.208+794.936+186.585+794.936+186.585C794.936+186.585+796.239+156.641+796.731+146.913Z"/>
</g>
      <g @click="selectBodyPart" id="右颈部" data-name="右颈部" name="右颈部">
<path class="cls-1"
      d="M730.246+124.625C730.042+125.913+730.584+133.547+731.272+141.794C732.056+151.169+733.03+161.336+733.319+163.877C733.665+166.91+735.64+181.907+735.908+185.569C740.166+186.515+756.416+190.605+759.986+191.164C757.233+184.067+754.334+171.698+752.837+164.986C751.418+158.621+751.017+152.398+750.587+145.989C745.283+142.357+733.623+131.095+730.246+124.625Z"/>
</g>
      <g @click="selectBodyPart" id="左颈部" data-name="左颈部" name="左颈部">
<path class="cls-1"
      d="M797.169+132.264C797.604+135.017+796.052+157.985+795.928+162.368C795.799+166.927+795.454+179.195+794.922+186.492C790.664+187.438+773.933+190.78+770.363+191.339C773.115+184.242+776.823+171.684+778.32+164.973C779.739+158.607+779.868+153.618+780.298+147.209C785.804+142.908+793.503+136.198+797.169+132.264Z"/>
</g>
      <g @click="selectBodyPart" id="口鼻" data-name="口鼻" name="口鼻">
<path class="cls-1"
      d="M779.65+74.3633C776.101+74.7086+771.074+75.1746+768.131+73.6828C764.96+75.0876+759.803+74.711+753.797+74.0822C757.543+74.9428+759.455+75.6382+760.454+78.0316C761.639+80.8727+761.28+91.0817+760.295+94.0951C760.208+97.63+758.102+110.026+757.081+112.378C754.768+117.332+750.076+121.516+746.292+125.376C748.058+128.122+753.255+133.551+755.633+134.432C759.05+137.275+771.811+138.02+777.716+135.143C781.461+133.318+784.439+131.168+785.925+127.555C783.793+124.209+779.741+117.044+778.825+112.245C778.071+109.494+775.565+98.9049+775.674+93.918C774.754+91.5163+774.667+81.4621+774.956+78.9703C775.072+76.5364+777.434+74.6507+779.65+74.3633Z"/>
</g>
      <g @click="selectBodyPart" id="右颊" data-name="右颊" name="右颊">
<path class="cls-1"
      d="M730.252+124.537C729.48+121.247+728.675+118.486+728.406+113.996C728.238+110.069+727.051+101.101+726.459+98.2526C729.13+97.8908+730.689+97.4327+733.477+96.2647C735.165+99.4675+742.527+99.3391+746.193+99.4082C754.776+98.8784+758.592+98.9483+760.222+94.1079C760.317+98.4691+758.02+109.144+757.11+112.297C756.224+115.365+749.895+122.023+746.164+125.309C741.531+125.443+735.545+124.578+730.252+124.537Z"/>
        </g>
      <g @click="selectBodyPart" id="下颌" data-name="下颌" name="下颌">
        <path class="cls-1"
              d="M799.219+128.825C796.461+134.43+788.482+140.297+785.264+143.214C781.646+146.494+777.931+149.9+766.418+151.937C760.56+150.652+753.363+148.358+748.422+144.449C743.481+140.539+733.489+130.427+730.176+124.509C734.869+124.746+741.39+125.35+746.114+125.35C747.906+127.685+752.419+132.546+754.857+134.038C757.517+135.667+761.427+137.242+768.594+137.024C776.356+136.789+783.527+133.278+785.861+127.631C790.423+128.336+796.015+129.151+799.219+128.825Z"/>
</g>
      <g @click="selectBodyPart" id="左颊" data-name="左颊" name="左颊">
<path class="cls-1"
      d="M798.976+128.834C803.041+123.057+802.995+111.12+803.265+106.63C802.091+103.524+804.126+98.5497+804.717+95.7012C802.046+95.3394+799.966+94.9324+797.178+93.7644C795.49+96.9672+791.575+98.883+787.909+98.9522C782.829+99.4993+777.296+98.9537+775.667+94.1132C775.571+98.4744+777.868+109.15+778.778+112.302C779.664+115.37+783.08+123.969+785.85+127.451C790.281+128.4+793.684+128.875+798.976+128.834Z"/>
</g>
      <g @click="selectBodyPart" id="右偏头" data-name="右偏头" name="右偏头">
<path class="cls-1"
      d="M737.34+42.1634C734.449+44.6328+733.817+47.6488+732.561+50.2031C731.807+49.4075+724.267+47.4561+721.127+51.811C719.661+48.0005+727.416+30.7654+734.66+27.2899C736.625+31.7118+751.499+37.9649+768.237+38.9525C754.645+38.1012+750.069+38.2328+746.898+38.5902C744.084+39.1261+739.35+40.4465+737.34+42.1634Z"/>
</g>
      <g @click="selectBodyPart" id="左偏头" data-name="左偏头" name="左偏头">
<path class="cls-1"
      d="M795.786+43.2663C798.677+45.7358+800.267+46.6581+801.523+49.2124C802.277+48.4168+804.831+45.5694+807.971+49.9242C809.437+46.1137+803.449+30.3274+796.205+26.8519C794.404+30.1599+782.931+37.3622+768.191+39.0371C781.784+38.1858+785.988+38.6184+789.212+39.414C791.683+40.2514+793.776+41.5495+795.786+43.2663Z"/>
</g>
      <g @click="selectBodyPart" id="颠顶" data-name="颠顶" name="颠顶">
<path class="cls-1"
      d="M796.206+26.7718C793.785+23.7864+779.286+14.6116+768.154+14.84C756.936+15.0701+743.89+17.3519+734.485+27.0874C747.43+26.5434+788.613+26.5434+796.206+26.7718Z"/>
</g>
      <g @click="selectBodyPart" id="前头部" data-name="前头部" name="前头部">
<path class="cls-1" stroke="#3bd8c1" stroke-width="0.1"
      d="M768.109+38.9239C776.738+38.7764+793.702+31.8434+796.136+26.8281C783.229+26.3118+748.564+26.6806+734.55+27.2706C737.722+33.8348+760.218+39.0588+768.109+38.9239Z"/>
</g>
      <g @click="selectBodyPart" id="右前额" data-name="右前额" name="右前额">
<path class="cls-1"
      d="M736.846+68.4651C737.025+69.9861+736.846+72.9479+736.271+77.0858C738.743+75.649+744.262+73.833+749.284+73.833C754.305+73.833+764.305+75.7567+768.181+73.5541C768.446+69.149+768.138+60.7057+768.138+57.6221C768.138+54.5385+768.314+45.4639+768.138+38.9443C762.851+38.9443+747.618+37.3782+743.82+39.2893C736.464+40.8904+734.733+44.6982+732.613+50.0638C735.344+56.1429+734.876+56.8904+735.639+60.2467C735.883+61.3185+736.501+65.534+736.846+68.4651Z"/>
</g>
      <g @click="selectBodyPart" id="左前额" data-name="左前额" name="左前额">
<path class="cls-1"
      d="M794.581+74.5895C792.301+74.1872+792.038+73.7681+787.016+73.7681C781.994+73.7681+772.098+75.9168+768.221+73.7142C767.957+69.3091+768.162+60.6408+768.162+57.5572C768.162+54.4736+767.986+45.3991+768.162+38.8795C773.448+38.8795+787.897+37.8223+791.421+40.201C794.945+42.5798+801.112+46.7206+801.377+49.2756C798.646+55.3547+798.381+58.8788+798.029+62.0505C797.79+64.1968+796.892+69.5191+794.581+74.5895Z"/>
</g>
      <g @click="selectBodyPart" id="右颞部" data-name="右颞部" name="右颞部">
<path class="cls-1"
      d="M724.12+49.6344C726.165+48.7348+731.071+49.3481+732.625+50.1659C734.383+53.3552+735.038+56.7081+736.182+63.2094C736.777+66.5865+737.286+71.6734+736.146+77.0944C734.715+77.8097+733.598+78.2568+732.793+81.9225C732.48+85.7224+732.118+93.8931+733.477+96.367C730.324+97.4826+728.898+98.0522+726.519+98.2284C723.771+89.131+722.886+82.6885+721.647+73.657C721.08+68.3798+720.58+56.216+721.149+51.8568C722.128+50.6249+721.109+50.9594+724.12+49.6344Z"/>
</g>
      <g @click="selectBodyPart" id="左颞部" data-name="左颞部" name="左颞部">
<path class="cls-1"
      d="M797.889+79.4623C798.202+83.2622+798.564+91.4329+797.206+93.9067C800.359+95.0224+802.345+95.4283+804.724+95.6045C806.222+85.3846+809.923+71.6537+809.394+63.2839C809.306+55.4428+808.994+49.0681+805.606+47.8659C800.143+45.9277+798.469+57.381+798.117+62.4029C797.963+64.5907+796.235+71.192+794.536+74.6342C795.967+75.3495+797.084+75.7965+797.889+79.4623Z"/>
</g>
      <g @click="selectBodyPart" id="右耳" data-name="右耳" name="右耳">
<path class="cls-1"
      d="M721.885+78.0899C720.968+76.2426+718.377+69.4019+712.728+75.2425C711.123+83.179+714.849+90.6091+716.225+94.1668C717.602+97.7246+719.133+107.031+723.797+107.168C728.461+107.304+727.085+104.499+727.314+102.857C727.543+101.215+724.561+92.4577+724.026+89.3105C723.491+86.1632+722.803+79.9372+721.885+78.0899Z"/>
</g>
      <g @click="selectBodyPart" id="左耳" data-name="左耳" name="左耳">
<path class="cls-1" stroke="#3bd8c1" stroke-width="0.1"
      d="M807.923+78.3108C808.75+76.4497+811.086+69.5577+816.178+75.4421C817.626+83.438+814.423+89.302+813.183+92.8864C811.942+96.4708+810.404+107.468+806.199+107.606C801.995+107.744+803.235+104.918+803.029+103.264C802.822+101.609+805.51+92.7862+805.993+89.6154C806.475+86.4446+807.095+80.1719+807.923+78.3108Z"/>
</g>
      <g @click="selectBodyPart" id="右眼" data-name="右眼" name="右眼">
<path class="cls-1"
      d="M737.611+76.3443C740.272+75.2856+743.263+74.1185+747.878+73.7983C762.922+73.9726+760.939+78.2614+761.058+86.9264C761.206+97.7311+757.287+98.807+751.235+99.0375C741.557+99.406+736.221+99.9661+733.282+96.2251C732.194+91.669+732.358+89.5464+732.576+86.045C732.865+81.4265+732.355+78.4349+737.611+76.3443Z"
/>
</g>
      <g @click="selectBodyPart" id="左眼" data-name="左眼" name="左眼">
<path class="cls-1" stroke="#3bd8c1" stroke-width="0.1"
      d="M774.915+86.9264C774.767+97.7311+778.686+98.807+784.739+99.0375C794.417+99.406+798.314+94.8647+798.196+88.6758C797.941+75.354+798.382+74.07+786.84+73.8562C772.567+73.5919+775.034+78.2614+774.915+86.9264Z"
/>
</g>
      <g @click="selectBodyPart" id="脑后" data-name="脑后" name="脑后">
<path class="cls-1"
      d="M257.377+96.118C254.757+93.4977+249.915+60.3311+252.168+49.985C253.554+43.6187+260.929+27.958+273.361+21.7837C281.529+17.727+303.648+13.2732+321.169+23.2855C321.169+23.2855+339.338+40.4071+339.525+53.9065C339.692+65.9213+336.376+92.9371+334.312+96.1802C328.512+96.9594+263.849+96.2919+257.377+96.118Z"
/>
</g>
      <g @click="selectBodyPart" id="右耳后" data-name="右耳后" name="右耳后">
<path class="cls-1"
      d="M329.097+139.672C330.078+126.517+332.173+108.781+334.398+96.2155C329.686+96.4119+320.72+96.5428+313.979+96.4119C313.52+100.077+313.651+118.271+316.269+122.721C318.887+127.172+321.374+131.491+329.097+139.672Z"
/>
</g>
      <g @click="selectBodyPart" id="左耳后" data-name="左耳后" name="左耳后">
<path class="cls-1"
      d="M263.14+144.17C263.14+130.115+259.702+108.586+257.477+96.0202C262.189+96.2165+272.595+96.5044+279.336+96.3735C279.794+100.038+279.938+120.688+277.321+125.138C274.703+129.589+271.507+136.23+263.14+144.17Z"
/>
</g>
      <g @click="selectBodyPart" id="右颈肩后部" data-name="右颈肩后部" name="右颈肩后部">
<path class="cls-1"
      d="M392.33+169.593C379.446+179.839+362.707+187.448+356.214+188.26C349.721+189.071+338.866+189.274+326.997+188.868C327.199+179.231+328.518+152.651+329.025+139.868C344.547+157.926+380.054+165.535+392.33+169.593Z"
/>
</g>
      <g @click="selectBodyPart" id="左颈肩后部" data-name="左颈肩后部" name="左颈肩后部">
<path class="cls-1"
      d="M202.322+164.514C215.206+174.761+235.401+185.266+241.894+186.078C248.387+186.889+261.935+188.881+273.805+188.475C272.156+183.579+269.275+171.468+268.292+167.266C267.327+163.134+265.77+156.641+263.295+144.494C242.279+160.961+217.463+161.994+202.322+164.514Z"
/>
</g>
      <g @click="selectBodyPart" id="颈项" data-name="颈项" name="颈项">
<path class="cls-1"
      d="M279.392+96.4273C288.191+96.5655+308.804+96.4635+313.943+96.5915C313.773+102.861+313.576+117.081+315.473+121.374C317.37+125.667+325.698+137.112+329.135+139.369C328.686+149.189+327.487+178.729+326.974+188.833C322.081+188.533+279.8+188.526+273.789+188.596C271.972+181.188+264.145+151.137+263.154+144.538C267.639+139.535+277.643+126.946+278.542+122.553C279.44+118.16+279.94+102.426+279.392+96.4273Z"
/>
</g>
      <g @click="selectBodyPart" id="右肩_2" data-name="右肩" name="右肩">
<path class="cls-1"
      d="M392.545+169.451C400.441+170.242+414.608+177.814+419.951+185.293C426.645+194.666+440.174+215.542+434.074+248.047C426.54+233.738+414.499+214.449+411.784+211.333C406.611+205.393+399.01+200.363+393.301+197.14C387.768+194.017+377.005+192.593+357.308+188.116C366.156+185.967+384.604+176.603+392.545+169.451Z"/>
</g>
      <g @click="selectBodyPart" id="左肩_2" data-name="左肩" name="左肩">
<path class="cls-1"
      d="M202.154+164.256C191.871+166.108+179.044+174.962+173.701+182.245C167.006+191.37+157.25+212.427+163.351+244.075C170.884+230.143+184.115+210.861+186.83+207.826C192.003+202.043+197.291+192.992+201.398+191.216C209.211+190.343+222.767+188.785+246.907+186.902C229.852+184.213+210.095+171.219+202.154+164.256Z"/>
</g>
      <g @click="selectBodyPart" id="右上臂后部" data-name="右上臂后部" name="右上臂后部">
<path class="cls-1"
      d="M443.667+314.186C443.667+307.294+442.177+286.245+440.873+276.745C439.569+267.246+437.706+255.324+433.981+247.873C430.255+240.423+422.732+226.78+415.375+216.338C406.393+219.196+399.861+220.584+393.374+223.099C392.185+232.097+391.308+243.45+389.817+256.675C388.327+269.9+387.399+280.303+389.058+291.435C390.29+299.709+392.949+313.704+395.652+322.677C408.3+320.19+429.164+316.731+443.667+314.186Z"/>
</g>
      <g @click="selectBodyPart" id="左上臂后部" data-name="左上臂后部" name="左上臂后部">
<path class="cls-1"
      d="M158.444+310.073C158.185+303.627+158.95+283.882+159.955+274.946C160.959+266.011+163.688+254.969+163.418+243.95C167.028+236.837+174.009+225.823+181.3+215.772C190.788+218.098+200.674+220.831+207.543+222.933C207.763+232.932+208.937+274.025+209.117+284.863C209.248+292.71+209.055+303.333+207.853+316.022C194.55+314.186+173.686+311.893+158.444+310.073Z"/>
</g>
      <g @click="selectBodyPart" id="右肘后" data-name="右肘后" name="右肘后">
<path class="cls-1"
      d="M455.99+358.767C454.368+352.497+445.163+329.636+443.683+314.129C433.621+316.117+406.124+320.506+395.721+322.785C395.305+328.196+398.73+369.295+400.767+371.839C413.329+369.99+442.599+362.593+455.99+358.767Z"/>
</g>
      <g @click="selectBodyPart" id="左肘后" data-name="左肘后" name="左肘后">
<path class="cls-1"
      d="M149.387+355.644C150.595+349.281+157.988+325.711+158.45+310.141C168.62+311.465+196.773+314.841+207.747+316.183C207.72+322.642+207.379+360.626+205.774+364.355C193.118+363.332+163.001+358.584+149.387+355.644Z"/>
</g>
      <g @click="selectBodyPart" id="右前臂外侧" data-name="右前臂外侧" name="右前臂外侧">
<path class="cls-1"
      d="M468.969+449.788C465.997+435.93+462.022+409.84+461.558+401.04C461.095+392.24+457.896+371.017+455.996+358.729C445.254+362.135+416.097+369.156+400.847+371.869C404.218+392.098+416.631+418.988+420.453+425.588C424.274+432.188+435.59+456.169+436.864+459.643C446.595+457.425+456.204+454.469+468.969+449.788Z"/>
</g>
      <g @click="selectBodyPart" id="左前臂外侧" data-name="左前臂外侧" name="左前臂外侧">
<path class="cls-1"
      d="M144.933+448.156C146.642+434.086+148.246+407.744+147.913+398.938C147.58+390.132+148.098+364.505+149.498+355.583C160.503+358.006+190.398+362.944+205.831+364.27C204.299+384.72+194.277+412.757+191.067+419.675C187.857+426.593+179.589+451.524+178.634+455.099C168.742+453.769+158.069+451.666+144.933+448.156Z"/>
</g>
      <g @click="selectBodyPart" id="右手腕外侧" data-name="右手腕外侧" name="右手腕外侧">
<path class="cls-1"
      d="M478.616+479.972C476.656+477.293+470.496+457.941+468.956+449.823C462.961+451.655+447.348+457.608+436.981+459.732C439.626+465.991+445.225+479.966+442.56+488.917C452.166+490.027+471.662+484.046+478.616+479.972Z"/>
</g>
      <g @click="selectBodyPart" id="左手腕外侧" data-name="左手腕外侧" name="左手腕外侧">
<path class="cls-1"
      d="M135.315+477.853C144.642+474+143.019+457.29+145.053+448.006C153.171+450.442+167.526+453.432+178.549+455.141C176.712+461.684+172.276+477.105+176.045+485.65C166.654+487.959+142.727+481.02+135.315+477.853Z"/>
</g>
      <g @click="selectBodyPart" id="右手背" data-name="右手背" name="右手背">
<path class="cls-1"
      d="M478.607+480.028C483.289+483.398+490.399+494.954+491.513+500.827C492.627+506.7+495.456+513.499+501.414+516.38C507.371+519.261+506+526.103+500.996+525.577C495.992+525.05+495.158+523.682+495.87+531.74C496.582+539.798+499.898+567.633+497.467+571.801C495.036+575.968+489.089+574.822+487.605+571.397C486.121+567.973+488.052+580.021+485.601+582.923C483.15+585.824+477.189+586.215+475.73+580.25C474.271+574.286+475.207+584.186+470.003+582.014C464.799+579.843+462.706+574.156+462.968+569.718C463.229+565.28+458.158+572.439+455.483+566.355C452.807+560.271+451.564+548.94+449.314+543.606C447.063+538.272+441.342+531.29+440.729+518.013C440.117+504.736+439.634+494.891+442.473+488.962C452.442+489.807+469.585+485.221+478.607+480.028Z"/>
</g>
      <g @click="selectBodyPart" id="左手背" data-name="左手背" name="左手背">
<path class="cls-1"
      d="M135.366+477.854C130.685+481.224+123.283+492.813+122.169+498.686C121.055+504.558+117.561+508.69+111.603+511.571C105.646+514.452+105.056+520.896+109.676+522.889C115.801+525.532+124.623+515.543+123.911+523.601C123.199+531.659+123.612+567.448+126.042+571.615C128.473+575.783+133.239+573.809+134.723+570.384C136.207+566.959+135.053+576.382+137.504+579.284C139.956+582.185+145.916+582.576+147.376+576.611C148.835+570.647+147.899+580.547+153.103+578.375C158.306+576.204+159.059+569.96+158.797+565.522C158.535+561.084+163.607+568.243+166.282+562.159C168.957+556.075+169.961+545.79+172.211+540.456C174.461+535.121+177.475+529.977+178.087+516.7C178.699+503.423+178.837+491.699+175.997+485.77C164.939+486.967+148.183+483.028+135.366+477.854Z"/>
</g>
      <g @click="selectBodyPart" id="右腋下" data-name="右腋下" name="右腋下">
<path class="cls-1"
      d="M415.408+216.319C412.711+210.683+402.165+201.167+391.883+196.367C392.407+203.683+393.013+218.229+393.461+223.088C400.347+220.289+409.771+218.198+415.408+216.319Z"/>
</g>
      <g @click="selectBodyPart" id="左腋下" data-name="左腋下" name="左腋下">
<path class="cls-1"
      d="M181.28+215.607C186.336+208.221+200.612+189.236+201.157+191.318C204.851+192.892+206.461+192.508+209.953+195.49C209.783+204.156+207.756+219.856+207.453+223.141C200.414+220.365+186.917+217.486+181.28+215.607Z"/>
</g>
      <g @click="selectBodyPart" id="右肩胛" data-name="右肩胛" name="右肩胛">
<path class="cls-1"
      d="M391.887+196.431C392.206+201.696+393.177+220.049+393.394+223.076C392.139+232.814+390.126+254.707+388.829+266.413C385.009+266.918+331.929+263.61+327.426+263.481C326.778+249.171+326.935+204.334+327.074+188.837C336.899+189.287+348.7+189.172+357.287+188.125C363.206+190.055+386.519+193.996+391.887+196.431Z"/>
</g>
      <g @click="selectBodyPart" id="左肩胛" data-name="左肩胛" name="左肩胛">
<path class="cls-1"
      d="M200.662+191.615C204.04+192.271+207.056+193.4+210.305+195.634C209.832+201.033+207.681+221.179+207.552+222.993C208.163+233.554+208.095+254.183+208.851+267.317C212.671+267.822+272.454+264.282+276.957+264.154C276.367+248.557+274.895+204.827+273.85+188.577C264.026+189.027+254.823+187.773+246.237+186.726C240.318+188.656+209.237+188.592+200.662+191.615Z"/>
</g>
      <g @click="selectBodyPart" id="右背中右部" data-name="右背中右部" name="右背中右部">
<path class="cls-1"
      d="M388.638+266.54C387.621+272.697+388.087+294.277+391.236+302.687C384.493+315.617+375.259+336.071+373.57+345.684C362.947+346.695+336.414+346.879+322.508+346.702C322.626+339.123+326.256+276.744+327.439+263.548C343.047+264.652+379.652+266.002+388.638+266.54Z"/>
</g>
      <g @click="selectBodyPart" id="左背中右部" data-name="左背中右部" name="左背中右部">
<path class="cls-1"
      d="M208.713+267.08C208.887+275.65+209.438+296.688+208.272+307.59C215.015+320.52+218.491+335.566+220.181+345.18C230.803+346.19+267.649+347.327+281.555+347.151C281.437+339.571+277.807+277.193+276.624+263.997C261.016+265.1+224.969+267.241+208.713+267.08Z"/>
</g>
      <g @click="selectBodyPart" id="右腰背部" data-name="右腰背部" name="右腰背部">
<path class="cls-1"
      d="M373.479+345.695C372.225+352.194+368.75+366.985+370.904+375.005C374.186+387.22+377.366+402.5+376.832+413.197C367.202+416.605+326.974+443.43+321.501+447.648C321.709+426.422+321.97+363.727+322.612+346.771C334.625+346.919+360.271+346.783+373.479+345.695Z"/>
</g>
      <g @click="selectBodyPart" id="左腰背部" data-name="左腰背部" name="左腰背部">
<path class="cls-1"
      d="M220.373+345.043C221.627+351.541+224.476+373.323+222.322+381.342C219.04+393.558+220.593+411.385+221.126+422.082C240.346+423.197+280.677+443.425+286.15+447.643C285.942+426.417+282.181+364.185+281.538+347.228C269.525+347.377+233.581+346.131+220.373+345.043Z"/>
</g>
      <g @click="selectBodyPart" id="右臀" data-name="右臀" name="右臀">
<path class="cls-1"
      d="M347.485+430.274C355.089+435.613+372.077+450.821+376.122+459.072C380.167+467.324+385.63+491.077+382.594+506.316C378.798+525.368+377.254+542.395+349.75+543.69C322.245+544.984+306.546+532.734+306.385+524.159C306.223+515.584+305.372+490.485+305.645+482.517C311.688+477.161+321.61+447.81+321.61+447.81C321.61+447.81+339.788+434.878+347.485+430.274Z"/>
</g>
      <g @click="selectBodyPart" id="左臀" data-name="左臀" name="左臀">
<path class="cls-1"
      d="M261.397+434.973C252.906+443.053+239.286+458.492+235.241+466.744C231.196+474.995+224.918+494.604+226.887+510.017C228.409+521.924+236.494+549.805+263.999+551.099C291.503+552.394+305.756+531.8+305.918+523.225C306.08+514.65+305.781+490.348+305.508+482.38C299.328+474.963+286.32+447.572+286.32+447.572C286.32+447.572+270.435+438.671+261.397+434.973Z"/>
</g>
      <g @click="selectBodyPart" id="右胯_2" data-name="右胯" name="右胯">
<path class="cls-1"
      d="M376.852+413.048C378.98+417.806+387.422+451.644+388.403+461.676C389.951+477.498+396.582+505.185+398.513+542.791C386.986+542.696+371.305+543.318+353.518+543.444C380.013+539.853+378.612+521.57+382.111+508.485C385.397+498.396+381.856+470.039+376.395+459.307C372.611+452.522+355.451+434.775+347.14+430.332C351.922+426.899+369.222+416.291+376.852+413.048Z"/>
</g>
      <g @click="selectBodyPart" id="左胯_2" data-name="左胯" name="左胯">
<path class="cls-1"
      d="M221.188+422.087C220.127+428.211+217.617+465.158+216.147+474.875C213.769+490.595+203.179+513.253+203.98+550.9C217.209+550.9+240.125+551.491+257.875+550.328C237.361+546.554+230.479+524.657+228.127+517.303C223.986+504.354+228.416+479.735+233.54+470.139C236.823+463.097+253.746+439.978+261.713+434.944C256.695+431.867+233.437+423.23+221.188+422.087Z"/>
</g>
      <g @click="selectBodyPart" id="右大腿后部" data-name="右大腿后部" name="右大腿后部">
<path class="cls-1"
      d="M398.653+542.656C398.354+551.321+392.976+591.36+391.183+603.461C389.39+615.562+377.942+661.292+377.195+670.256C362.639+673.73+323.879+681.761+309.91+683.987C309.91+675.919+311.076+647.048+309.61+635.323C308.244+624.389+303.499+585.758+304.236+565.069C304.742+550.878+305.783+535.404+309.22+530.773C319.675+543.768+344.478+544.567+352.994+543.67C361.51+542.774+389.016+542.589+398.653+542.656Z"/>
</g>
      <g @click="selectBodyPart" id="腰骶部" data-name="腰骶部" name="腰骶部">
<path class="cls-1"
      d="M321.731+424.113L321.731+447.621C321.731+447.621+309.903+480.319+305.508+482.654C298.843+473.609+290.038+454.588+286.21+447.641C285.661+445.443+285.594+424.422+285.594+424.422L321.731+424.113Z"/>
</g>
      <g @click="selectBodyPart" id="腰脊正中部" data-name="腰脊正中部" name="腰脊正中部">
<path class="cls-1" d="M322.49+346.959L321.701+424.082L285.331+424.696L281.65+346.872L322.49+346.959Z"/>
</g>
      <g @click="selectBodyPart" id="脊背正中部" data-name="脊背正中部" name="脊背正中部">
<path class="cls-1" d="M327.522+263.427L322.559+346.906L281.539+347.275L276.722+263.915L327.522+263.427Z"/>
</g>
      <g @click="selectBodyPart" id="脊背上部正中" data-name="脊背上部正中" name="脊背上部正中">
<path class="cls-1"
      d="M327.127+188.963C327.418+195.087+326.818+251.417+327.433+263.635C316.71+263.371+291.22+263.899+276.805+264.25C277.244+252.208+274.381+201.704+273.992+188.395C278.22+188.687+322.415+188.581+327.127+188.963Z"/>
</g>
      <g @click="selectBodyPart" id="左足跟" data-name="左足跟" name="左足跟">
<path class="cls-1"
      d="M231.79+961.341C231.781+967.636+230.435+998.277+237.988+999.072C246.603+999.155+268.094+1000.79+271.695+995.031C275.85+988.382+272.44+984.492+269.509+962.715C259.852+962.152+239.388+961.95+231.79+961.341Z"/>
</g>
      <g @click="selectBodyPart" id="右足跟" data-name="右足跟" name="右足跟">
<path class="cls-1"
      d="M342.811+963.214C342.016+969.044+342.854+997.61+335.301+998.405C327.204+998.754+311.499+1000.85+308.567+994.357C305.34+987.211+301.867+984.864+304.798+963.086C314.082+962.877+336.138+963.226+342.811+963.214Z"/>
</g>
      <g @click="selectBodyPart" id="左踝后部" data-name="左踝后部" name="左踝后部">
<path class="cls-1"
      d="M230.93+911.841C231.242+917.032+231.808+935.501+231.55+941.314C231.291+947.128+230.068+955.68+231.802+961.358C238.085+962.089+258.798+961.919+269.553+962.766C268.852+955.86+270.3+949.44+270.419+944.167C270.537+938.894+265.996+926.789+265.996+914.021C260.683+913.784+238.497+912.367+230.93+911.841Z"/>
</g>
      <g @click="selectBodyPart" id="右踝后部" data-name="右踝后部" name="右踝后部">
<path class="cls-1"
      d="M348.446+913.903C347.787+919.063+345.608+937.287+345.498+943.106C345.388+948.925+345.005+957.658+342.847+963.208C336.311+963.516+315.9+962.961+304.739+963.085C305.911+956.24+304.422+947.607+304.642+942.338C304.861+937.068+311.707+923.066+313.169+913.652C318.671+913.772+340.598+913.921+348.446+913.903Z"/>
</g>
      <g @click="selectBodyPart" id="左小腿后部" data-name="左小腿后部" name="左小腿后部">
<path class="cls-1"
      d="M223.933+730.891C223.954+742.893+219.592+757.393+217.436+778.934C215.28+800.476+215.422+836.732+222.278+860.689C229.134+884.646+229.747+904.553+230.778+911.861C241.704+912.173+253.569+913.396+266.002+914.036C266.088+902.118+278.77+833.25+279.401+819.221C280.032+805.193+275.948+761.081+276.612+751.105C263.952+745.406+236.005+735.651+223.933+730.891Z"/>
</g>
      <g @click="selectBodyPart" id="右小腿后部" data-name="右小腿后部" name="右小腿后部">
<path class="cls-1"
      d="M372.38+727.322C371.487+739.291+374.785+754.069+375.37+775.71C375.955+797.351+372.25+833.225+363.672+856.62C355.093+880.016+350.013+906.91+348.454+914.123C337.534+913.64+325.611+913.998+313.165+913.733C313.945+901.84+310.252+825.426+310.641+811.389C311.031+797.351+318.309+755.065+318.371+745.067C331.412+740.303+359.995+731.193+372.38+727.322Z"/>
</g>
      <g @click="selectBodyPart" id="左腘窝" data-name="左腘窝" name="左腘窝">
<path class="cls-1"
      d="M219.582+671.666C221.645+689.582+224.741+718.942+223.99+730.948C237.685+735.919+256.914+743.048+276.518+751.115C285.804+727.853+287.548+696.743+288.227+684.308C276.2+682.27+234.983+674.799+219.582+671.666Z"/>
</g>
      <g @click="selectBodyPart" id="右腘窝" data-name="右腘窝" name="右腘窝">
<path class="cls-1"
      d="M377.428+670.475C376.381+687.229+372.339+717.069+372.339+727.278C359.593+731.225+338.378+738.215+318.149+745.204C312.146+724.976+310.831+696.524+310.151+684.089C322.178+682.051+362.026+673.608+377.428+670.475Z"/>
</g>
      <g @click="selectBodyPart" id="左大腿后部" data-name="左大腿后部" name="左大腿后部">
<path class="cls-1"
      d="M203.886+550.8C204.195+559.161+204.967+596.295+206.826+607.971C208.684+619.648+218.423+662.567+219.197+671.217C234.289+674.569+273.625+682.134+288.108+684.282C288.108+676.498+289.645+652.42+290.536+641.039C291.577+627.743+300.364+597.565+300.133+575.944C299.986+562.242+300.711+547.848+299.439+536.748C288.599+549.288+272.383+552.588+257.353+550.854C247.508+550.966+213.877+550.735+203.886+550.8Z"/>
</g>

</svg>
    <v-btn absolute small top left @click="changeSide">{{ front ? '背面' : '正面' }}</v-btn>
  </div>
</template>

<script>
import img from "@/utils/BodyImage"
import {dc, getNodeFromPath, getPath} from "./utils/SymptomTreePath"

export default {
  name: "BodySelector",
  model: {
    prop: 'selection',
    event: 'input'
  },
  props: {
    activeParts: {
      type: Array,
      default: function () {
        return []
      }
    },
    tempParts: {
      type: Array,
      default: function () {
        return []
      }
    },
    height: {
      type: String,
      default: '100vh'
    },
    toggle: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    img,
    front: true,
    page: "512 0 512 1024",
  }),
  watch: {
    activeParts: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!v) return
        this.renderParts()
      }
    },
    tempParts: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!v) return
        this.renderParts()
        if (v.length) v.forEach((name) => {
          let els = document.getElementsByName(name)
          if (els.length) {
            els.forEach(e => e.children[0].style.fill = `rgba(160, 255, 122, ${0.66})`)
          }
        })
      }
    }
  },
  mounted() {
  },
  methods: {
    renderParts() {
      let v = this.activeParts
      // 初始化body颜色
      for (let node of document.getElementsByClassName('cls-1')) {
        node.style.fill = 'rgba(255, 255, 255, 0.1)'
      }
      // 高亮关联bodyPart
      v.forEach(({name, val}) => {
        let els = document.getElementsByName(name)
        if (els.length) {
          val = val * 0.15
          val = val > 0.85 ? 0.85 : val
          els.forEach(e => e.children[0].style.fill = `rgba(160, 255, 122, ${val})`)
        }
      })
    },

    selectBodyPart(e) {
      let name = e.currentTarget.dataset.name
      this.$emit('selectBodyPart', name)
    },

    changeSide() {
      this.page = this.page === '512 0 512 1024' ? '30 0 512 1024' : '512 0 512 1024'
      this.front = this.page === '512 0 512 1024'
    }
  }
}
</script>

<style scoped>
.image-box {
  height: 100%;
}

.cls-1 {
  fill: rgba(255, 255, 255, 0.1);
  stroke: aqua;
  stroke-width: 0.5px;
}

.cls-1:hover {
  stroke: rgb(122, 255, 160);
  fill: rgba(122, 255, 160, 0.5) !important;
}

.cls-1, .cls-2 {
  fill-rule: evenodd;
}

</style>