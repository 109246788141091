const dc = j => JSON.parse(JSON.stringify(j))
const getPath = (line, check) => {
    let paths = [],
        stop = false,
        iter = (list, path = [{id: 'ROOT', name: 'ROOT'}]) => {
            for (let i = 0; i < list.length; i++) {
                let li = list[i],
                    _path = dc(path)
                _path.push({id: li.id, name: li.name})
                if (check && check(_path)) {
                    paths = _path
                    stop = true
                    return
                }
                if (!stop) {
                    if (li.children.length) iter(li.children, _path)
                    else paths.push(_path)
                }
            }
        }
    iter(line)
    return check ? ({stop, paths, endNode: stop ? paths[paths.length - 1] : null}) : paths
}
const getNodeFromPath = (list, id) => {
    let {stop, paths} = getPath(list, path => path.find(p => p.id === id))
    if (stop) return paths[paths.length - 1]
    else return null
}

module.exports = {
    dc, getPath, getNodeFromPath
}