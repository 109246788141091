<template>
  <div class="d-flex" style="min-width: 750px">
    <v-card min-width="375px" tile>
      <v-card-title class="cyan darken-1 white--text">快速问卷编辑</v-card-title>
      <!--      <v-card-title>快速日记编辑</v-card-title>-->
      <div class="pl-2 pr-4 pt-4 d-flex align-center subtitle-1">
        <v-icon color="primary">mdi-circle-medium</v-icon>
        问卷标题
      </div>
      <v-card-text class="pt-0 pl-8">
        <v-text-field v-model="scale.title"
                      dense
                      hide-details
        />
      </v-card-text>

      <!-- DONE 导入客户症状类healthItems -->
      <!-- DONE 问卷healthItem自动生成问题文本 -->
      <div class="pl-2 pr-4 d-flex align-center subtitle-1">
        <v-icon color="primary">mdi-circle-medium</v-icon>
        应用范围
        <v-spacer/>
        <v-switch v-model="scale.common"
                  label="常用"
                  dense
                  flat
                  color="primary"
                  hide-details
                  class="mt-0 pt-0"
                  style="transform: scale(0.8)"
        />
        <template>
          <v-divider vertical class="mx-1"/>
          <v-switch v-model="scale.specificCustomer"
                    label="仅当前用户"
                    dense
                    flat
                    color="primary"
                    hide-details
                    class="mt-0 pt-0"
                    style="transform: scale(0.8)"
          />
        </template>
      </div>
      <!-- DONE healthItem搜索添加 -->
      <div class="pl-2 pr-4 d-flex align-center subtitle-1">
        <v-icon color="primary">mdi-circle-medium</v-icon>
        客户症状
      </div>
      <div class="px-4 pb-2">
        <v-autocomplete
            :items="searchHealthItems"
            :loading="$store.state.loading"
            :search-input.sync="healthItemSearch"
            no-filter
            :auto-select-first="false"
            :hide-selected="false"
            no-data-text="未找到相似症状"
            hide-details
            item-text="name"
            item-value="id"
            label="症状搜索"
            placeholder="输入关键字"
            prepend-icon="mdi-database-search"
            return-object
            class="mt-0 pt-0"
            style="z-index: 10;width: 28em!important;flex-grow:0"
            clearable
            @keypress.enter="goSearchHealthItems(healthItemSearch)"
            @change="selectHealthItemFromSearch($event)"
        ></v-autocomplete>
      </div>
      <div v-if="[...inputSymptoms, ...searchSymptoms].length" class="mx-4 pa-2 rounded border-1"
           style="border-color: #607d8b !important">
        <v-chip v-for="item in [...inputSymptoms, ...searchSymptoms]"
                :key="item.id"
                small
                outlined
                :color="item.fromSearch?'teal':''"
                class="mr-2 mb-1"
        >
          {{ item.text || item.name }}
          <v-menu right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small
                      right
                      v-bind="attrs"
                      v-on="on">
                mdi-information-outline
              </v-icon>
            </template>

            <v-list dense class="pa-0">
              <v-list-item dense @click="addScaleItemFromHealthItem('change',item)">
                <v-list-item-title>症状变化</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="addScaleItemFromHealthItem('severity',item)">
                <v-list-item-title>症状程度</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-chip>
      </div>

      <!-- DONE 问卷问题编辑 -->
      <div class="my-4 green lighten-5">
        <div class="pl-2 pr-4 d-flex align-center subtitle-1">
          <v-icon color="primary">mdi-circle-medium</v-icon>
          <template v-if="editingItem">
            问题编辑
            <v-switch v-model="editingItem.mandatory"
                      :label="editingItem.mandatory?'必选':'可选'"
                      dense
                      flat
                      color="red"
                      hide-details
                      class="mt-0 ml-2 pt-0"
                      style="transform: scale(0.8)"
            />
          </template>
          <div v-else>从右侧选择问题, 或新建问题</div>
          <v-spacer/>
          <v-btn icon @click="addScaleItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-card-text v-if="editingItem" class="pt-0 pl-8">
          <v-text-field v-model="editingItem.id"
                        label="·问题ID"
                        readonly
                        dense
                        hide-details
                        class="mt-6"
          />
          <v-text-field v-model="editingItem.text"
                        label="·问题文本"
                        dense
                        hide-details
                        class="mt-6"
          />
          <v-select v-model="editingItem.type"
                    :items="itemTypes"
                    item-text="field"
                    item-value="value"
                    label="·问题类型"
                    dense
                    hide-details
                    class="mt-6"
          />
          <v-textarea v-if="['multi', 'single'].includes(editingItem.type)"
                      label="·选项"
                      :value="editingItem.options.join(' | ')"
                      @input="editingItem.options=$event.split(' | ')"
                      rows="2"
                      auto-grow
                      hide-details
                      class="mt-3"
          />
          <v-select v-if="['multi', 'single'].includes(editingItem.type)"
                    v-model="scale.default[editingItem.id]"
                    :items="editingItem.options"
                    label="·默认值"
                    :multiple="editingItem.type==='multi'"
                    dense
                    hide-details
                    class="mt-6"
          />
          <v-text-field v-if="['text', 'number'].includes(editingItem.type)"
                        v-model="scale.default[editingItem.id]"
                        label="·默认值"
                        :type="editingItem.type"
                        hide-details
                        class="mt-2"
          />
          <!-- DONE 问题valid设置方法 -->
          <div class="mt-2">
            <div class="mb-1 caption grey--text text--darken-2">
              关联条件(满足下列之一即可)
            </div>
            <div class="d-flex flex-wrap">
              <v-chip v-for="(v,i) in editingItem.valid"
                      :key="i"
                      small
                      :dark="v.lost"
                      :color="v.lost?'red':''"
                      close
                      class="mr-2 mb-1"
                      @click:close="editingItem.valid.splice(i,1)"
              >
                {{ v.scaleItemId }}.{{ v.option }}
              </v-chip>
              <span class="caption">{{ editingItem.valid.length ? '' : '无关联条件, 默认显示' }}</span>
            </div>
          </div>
        </v-card-text>
      </div>

      <!-- DONE 问卷cautions -->
      <div class="pl-2 pr-4 subtitle-1 d-flex align-end">
        <v-icon color="primary">mdi-circle-medium</v-icon>
        预警条件<span class="caption">(满足下列之一即可)</span>
        <v-spacer/>
        <v-btn text small @click="checkOptionCautions">查看</v-btn>
      </div>
      <div class="px-8 pb-4 mt-2 d-flex flex-wrap">
        <v-chip v-for="(v,i) in scale.optionCautions"
                :key="i"
                small
                :dark="v.lost"
                :color="v.lost?'red':''"
                close
                class="mr-2 mb-1"
                @click:close="scale.optionCautions.splice(i,1)&&setOptionCautions()"
        >
          {{ v.scaleItemId }}.{{ v.option }}
        </v-chip>
        <div class="pr-4 caption">{{ scale.optionCautions.length ? '' : '无预警条件' }}</div>
      </div>

      <!-- DONE 问卷渲染方法 -->
      <div class="pl-2 pr-4 subtitle-1 d-flex align-end">
        <v-icon color="primary">mdi-circle-medium</v-icon>
        渲染模板
        <v-spacer/>
        <v-btn text small @click="render">渲染</v-btn>
      </div>
      <div class="pl-8 pr-4">
        <v-chip v-for="item in scale.items"
                :key="item.id"
                small
                outlined
                class="mt-2"
                @click="insertContentHandle(`((${item.id}))`)"
        >
          "{{ item.text }}"问题的结果
        </v-chip>
      </div>
      <div class="mt-2 pl-8 pr-4 pb-4 d-flex flex-wrap">
        <v-textarea v-model="scale.renderModel"
                    id="render-model"
                    rows="3"
                    auto-grow
                    outlined
                    dense
                    hide-details
                    clearable
                    class="pt-0 mt-0"
                    @blur="blurEvent"
        />
      </div>

      <!-- DONE 问卷healthItems -->
      <div class="pl-2 pr-4 subtitle-1 d-flex align-end">
        <v-icon color="primary">mdi-circle-medium</v-icon>
        关联健康数据
        <v-spacer/>
        <v-btn text small @click="calHealthItems">计算</v-btn>
      </div>
      <div class="px-8 pb-4 mt-2 d-flex flex-wrap">
        <v-chip v-for="(v,i) in scale.healthItems"
                :key="i"
                small
                :dark="v.lost"
                :color="v.lost?'red':''"
                close
                class="mr-2 mb-1"
                @click:close="scale.healthItems.splice(i,1)&&setHealthItems()"
        >
          {{ `${v.healthItemName}:${v.mode === 'change' ? '变化' : '程度'}` }}
        </v-chip>
        <div class="pr-4 caption">{{ scale.healthItems.length ? '' : '无关联健康数据' }}</div>
      </div>
      <v-card-text class="d-flex justify-space-between">
        <v-btn text color="success" style="width: 75%!important;" @click="$emit('save', scale)">保存</v-btn>
        <v-divider vertical class="mx-2"/>
        <v-btn text color="grey darken-2" class="flex-grow-1" @click="$emit('close')">关闭</v-btn>
      </v-card-text>
    </v-card>
    <fast-scale-card v-if="scale"
                     width="375"
                     :scale="scale"
                     :edit-mode="true"
                     @update="scaleAnswer=$event"
                     @editing="editingItem=$event"
                     @delete="deleteScaleItem"
                     @setOptionCautions="scale.optionCautions.find(v=>v.scaleItemId===$event.scaleItemId&&v.option===$event.option)||scale.optionCautions.push($event)"
    />
  </div>
</template>

<script>
import FastScaleCard from "./FastScaleCard";
import {customAlphabet} from 'nanoid'
import * as SillyDate from "silly-datetime";

SillyDate.locate('zh-cn')
const nanoid = customAlphabet('qwertyuiopasdfghjklzxcvbnm', 5)

export default {
  name: "FastScaleEditor",
  components: {FastScaleCard},
  props: {
    value: Object,
    inputSymptoms: {
      type: Array,
      default: function () {
        return []
      }
    },
    healthPlans: Object,
    lastRecordTime: Number,
  },
  data: () => ({
    searchHealthItems: [],
    healthItemSearch: '',
    scale: undefined,
    itemTypes: [
      {field: '单选', value: "single"},
      {field: '多选', value: "multi"},
      {field: '文本', value: "text"},
      {field: '数值', value: "number"}
    ],
    editingItem: undefined,
    optionCautionFns: [],
    scaleAnswer: {},
    blurIndex: -1,
    healthItemFns: [],
    searchSymptoms: []
  }),
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v || JSON.stringify(v) === JSON.stringify(this.scale)) return
        if (!v.id) v.id = nanoid(10)
        this.scale = JSON.parse(JSON.stringify(v))
        // console.log(this.scale)
        // 解析optionCautions, HealthItems,
      }
    },
    scale: {
      deep: true,
      handler(v) {
        if (!v) return
        this.$emit('input', v)
        this.setOptionCautions()
      }
    },
    editingItem: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        // 处理多选/单选切换, 默认值格式问题
        if (v.type === 'multi' && typeof this.scale.default[v.id] === 'string') this.$set(this.scale.default, v.id, [this.scale.default])
        if (v.type === 'single' && typeof this.scale.default[v.id] !== 'string') this.$set(this.scale.default, v.id, this.scale.default[0])
        this.$set(this.scale.items, this.scale.items.findIndex(s => s.id === v.id), v)
        // console.log(this.scale.items)
        // 检查valid和cautions, 以防修改选项导致的匹配问题
        this.checkValidCautionHealthItemChanging()
      }
    },
  },
  methods: {
    nanoid,
    goSearchHealthItems(val) {
      if (this.isLoading || !val) return
      this.isLoading = true
      this.$store.dispatch('request', {
        action: 'searchHealthItems',
        params: {
          keywords: val,
          categories: ['symptom']
        },
        fn: (data) => {
          this.searchHealthItems = data
        }
      })
    },
    selectHealthItemFromSearch(e) {
      if (!e) return
      let flag = false
      const idx = this.searchSymptoms.findIndex(d => d.id === e.id)
      if (idx > -1) {
        this.$pop(e.name + '已经存在!')
        flag = true
      }
      if (!flag) this.searchSymptoms.push(({...e, fromSearch: true}))
    },
    checkValidCautionHealthItemChanging() {
      const itemNames = []
      let checkCaution = false
      let checkHealthItem = false
      this.scale.items.forEach(item => {
        item.valid.forEach(e => {
          const {scaleItemId, option} = e
          const _item = this.scale.items.find(i => i.id === scaleItemId)
          const lost = !_item?.options.includes(option)
          if (lost) itemNames.push(item.text)
          this.$set(e, 'lost', lost)
        })
      })
      this.scale.optionCautions.forEach(e => {
        const {scaleItemId, option} = e
        const item = this.scale.items.find(i => i.id === scaleItemId)
        const lost = !item?.options.includes(option)
        if (checkCaution || lost) checkCaution = true
        this.$set(e, 'lost', lost)
      })
      this.scale.healthItems.forEach(e => {
        const {scaleItemId} = e
        const lost = !this.scale.items.find(i => i.id === scaleItemId)
        if (checkHealthItem || lost) checkHealthItem = true
        this.$set(e, 'lost', lost)
      })
      if (checkCaution || itemNames.length || checkHealthItem) this.$pop({
        text: (itemNames.length ? `请检查下列问题的关联情况：${itemNames.join('、')}；` : '')
            + `${checkCaution ? '请检查预警选项！' : ''}${checkHealthItem ? '请检查关联健康条件！' : ''}`,
        color: 'warning',
        top: true,
        t: 5
      })
    },
    setOptionCautions() {
      this.optionCautionFns = this.scale.optionCautions.map(v => {
        const {scaleItemId, scaleItemType, option} = v
        if (scaleItemType === 'single') return eval(`self=>({scaleItemId:'${scaleItemId}',option:'${option}',caution:self['${scaleItemId}']==='${option}'})`)
        else if (scaleItemType === 'multi') return eval(`self=>({scaleItemId:'${scaleItemId}',option:'${option}',caution:self['${scaleItemId}'].includes('${option}')})`)
      })
    },
    checkOptionCautions() {
      const fns = this.optionCautionFns
      // console.log(fns)
      const res = []
      fns.forEach(fn => {
        const r = fn(this.scaleAnswer.answer)
        if (r.caution) {
          res.push({
            ...r,
            scaleItemName: this.scale.items.find(s => s.id === r.scaleItemId).text
          })
        }
      })
      const cautionText = res.map(r => `问题:${r.scaleItemName},选项:${r.option}`).join(' | ')
      this.$pop({
        text: cautionText || '无预警提示',
        color: cautionText ? 'warning' : 'teal',
        multiline: true
      })
    },
    // 获取光标所在位置的index
    blurEvent(e) {
      this.blurIndex = e.target.selectionStart;
    },
    // 插入内容的方法
    insertContentHandle(text) {
      let index = this.blurIndex
      let str = this.scale.renderModel || ''
      this.$set(this.scale, 'renderModel', str.slice(0, index) + text + str.slice(index))
      document.getElementById('render-model').focus()
    },
    render() {
      const m = this.scale.renderModel
      const r = /[(（]{2}(.*?)[）)]{2}/g
      let a
      let i = 0
      let text = ''
      while (a = r.exec(m)) {
        text += m.substring(i, a.index)
        text += this.scaleAnswer.answer[a[1]]
        i = a.index + a[0].length
      }
      text += m.substring(i)
      try {
        this.$pop({
          text,
          color: 'success',
          multiline: true
        })
      } catch (e) {
        console.log(e)
        this.$pop({
          text: '渲染模板格式错误',
          color: 'error',
          top: true,
          timeout: 3
        })
      }
    },
    deleteScaleItem(e) {
      this.scale.items.splice(this.scale.items.findIndex(s => s.id === e.id), 1)
      this.checkValidCautionHealthItemChanging()
    },
    addScaleItem() {
      this.editingItem = {
        id: nanoid(5),
        text: '',
        type: '',
        mandatory: true,
        options: [],
        valid: [],
      }
      this.scale.items.push(this.editingItem)
    },
    addScaleItemFromHealthItem(mode, item) {
      const timeText = mode === 'change'
          ? '自上次记录以来，' + `${item.text || item.name}症状有变化么？`
          : `您${item.text || item.name}的程度怎么样？`
      const id = nanoid(5)
      this.editingItem = {
        id,
        text: `${timeText}`,
        type: 'single',
        mandatory: true,
        options: mode === 'change' ? ['无', '缓解', '维持', '加重'] : ['无', '轻', '中', '重', '严重'],
        valid: [],
      }
      this.scale.items.push(this.editingItem)
      this.healthItemFns.push({
        scaleItemId: id,
        healthItemName: item.name,
        healthItemCategory: item.category,
        mode,
        fn: mode === 'change'
            ? eval(`(self,scale)=>self['${id}']&&({type:'${mode}',name:'${item.name}',category:'${item.category}',multiple:[0,0.85,1,1.2][scale.items.find(i=>i.id==='${id}').options.indexOf(self['${id}'])]})`)
            : eval(`(self,scale)=>self['${id}']&&({type:'${mode}',name:'${item.name}',category:'${item.category}',value:[0,0.7,1,1.5,2][scale.items.find(i=>i.id==='${id}').options.indexOf(self['${id}'])]})`)
      })
      this.setHealthItems()
    },
    setHealthItems() {
      this.$set(this.scale, 'healthItems', this.healthItemFns.map(h => ({
        scaleItemId: h.scaleItemId,
        healthItemName: h.healthItemName,
        healthItemCategory: h.healthItemCategory,
        mode: h.mode
      })))
    },
    calHealthItems() {
      const fns = this.healthItemFns
      const res = []
      fns.forEach(e => {
        const r = e.fn(this.scaleAnswer.answer, this.scale)
        if (r) {
          res.push(r)
        }
      })
      // console.log(res)
      const text = res.map(r => `${r.name}=${r.type === 'change' ? ('×' + r.multiple) : r.value}`).join(' | ')
      this.$pop({
        text: text || '无关联健康数据',
        color: 'teal',
        multiline: true,
        timeout: 5
      })
    }
  }
}
</script>

<style scoped>

</style>