<template>
  <div class="w-100p d-flex blue-grey overflow-y-auto"
       :style="{height: `calc(100vh - ${marginTop})`, marginTop: marginTop, marginLeft: '96px'}">
    <div class="h-100p overflow-y-auto">
      <template v-if="recordId">
        <record v-if="record.hpi"
                :record="record"
                :show-diagnose-options="false"
                class="mb-4"
                style="width: 960px"
        />
        <v-card-text v-else>未找到健康报告信息</v-card-text>
      </template>

      <v-tabs v-model="planTab">
        <v-tab>产品选择</v-tab>
        <v-tab>用药提醒</v-tab>
        <v-tab>健康日记</v-tab>
      </v-tabs>

      <v-tabs-items v-model="planTab" style="background-color: rgba(0,0,0,0)">
        <!-- 产品选择与录入 -->
        <v-tab-item>
          <!-- 症状/证型/体质/疾病录入 -->
          <v-card v-if="showHealthItems" width="960"
                  class="mb-4 pb-4"
          >
            <v-card-title class="pb-2">
              健康条件
              <v-spacer/>
              <v-btn v-if="buffHealthItems" icon color="grey darken-4" class="ml-4"
                     @click="healthItems=JSON.parse(buffHealthItems)">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-card-title>
            <!-- TODO 常见实体 -->
            <!-- 实体搜索 -->
            <v-card-text class="px-4">
              <v-autocomplete
                  :items="productItems"
                  :loading="isLoading"
                  :search-input.sync="productSearch"
                  no-filter
                  :auto-select-first="false"
                  :hide-selected="false"
                  no-data-text="未找到相似条件"
                  hide-details
                  item-text="text"
                  item-value="id"
                  label="诊断搜索"
                  placeholder="输入关键字"
                  prepend-icon="mdi-database-search"
                  return-object
                  style="z-index: 10;width: 28em!important;flex-grow:0"
                  clearable
                  @keypress.enter="goSearchHealthItems(productSearch)"
                  @change="selectHealthItemFromSearch($event)"
              ></v-autocomplete>
            </v-card-text>
            <!-- 程度录入 -->
            <template v-for="group in healthItemGroups">
              <v-card-subtitle class="pt-0 pb-2 px-6 font-weight-bold">{{ group.name }}</v-card-subtitle>
              <v-card-text class="d-flex flex-wrap">
                <div v-for="(item, i) in group.items"
                     :key="item.id"
                     :class="['d-flex align-center px-2 rounded', i%4===0||i%4===1?'blue-grey lighten-5':'', i%2?'ml-2':'']"
                     :style="{width: i%2?'50%':'calc(50% - 10px)'}"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" style="width: 8em; flex-shrink: 0"
                           v-bind="attrs"
                           v-on="on"
                      >{{ item.text || item.name }}
                      </div>
                    </template>
                    <span>{{ item.text ? `${item.text}。` : '' }}关联实体:{{ item.name }}</span>
                  </v-tooltip>

                  <v-slider
                      v-model="item.value"
                      :step="categories[group.category].step"
                      dense
                      :thumb-size="18"
                      ticks
                      :max="categories[group.category].max"
                      min="0"
                      :color="getItemValueTip(group.category, item.value).color"
                      hide-details
                  ></v-slider>
                  <div :class="['m-2 d-flex justify-end align-center flex-shrink-0', i%2?'pr-2':'' ]"
                       style="width: 5em">
                    {{ getItemValueTip(group.category, item.value).text }}
                    <v-btn icon
                           outlined
                           x-small
                           class="ml-1 mr-n1"
                           @click="removeItem(item.id)"
                    >
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </template>
            <!--  产品类别选择  -->
            <v-divider/>
            <v-card-text class="pb-2">
              <v-card-subtitle class="pt-0 pb-2 px-2 font-weight-bold d-flex align-center">
                产品类别
                <v-spacer/>
                推荐数量
                <v-slider
                    v-model="recommendCount"
                    :step="1"
                    dense
                    :thumb-size="18"
                    thumb-label="always"
                    ticks
                    min="1"
                    max="10"
                    hide-details
                ></v-slider>
                <v-divider vertical class="mx-4"/>
                <v-btn outlined color="cyan darken-1" :loading="isLoading" @click="recommendProducts">获得推荐方案</v-btn>
              </v-card-subtitle>
              <v-chip-group
                  v-model="productCategories"
                  column
                  multiple
              >
                <v-chip v-for="i in productCategoryItems"
                        :key="i.category"
                        filter
                        outlined
                        :value="i"
                        @click.stop=""
                >
                  {{ i.name }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>

          <!-- TODO 饮食禁忌/喜好录入 -->
          <!-- TODO 常见标签 -->
          <!-- TODO 标签搜索 -->

          <v-card v-show="cautionItems.length" width="960" min-height="160" class="mb-4">
            <v-card-title class="warning lighten-1 white--text">
              产品注意事项
            </v-card-title>
            <v-card-text>
              <v-chip-group
                  v-model="cautions"
                  column
                  multiple
              >
                <v-chip v-for="i in cautionItems"
                        :key="i.item"
                        filter
                        outlined
                        :value="i"
                >
                  {{ i.item }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>

          <!-- 分组分类产品展示 -->
          <v-card width="960" min-height="320">
            <v-card-title class="cyan darken-2 white--text">
              产品面板
              <v-spacer/>
              <v-switch
                  v-show="cautionCategories.includes(productCategoryItems[curCategory].category)"
                  v-model="showUnrecommended"
                  :label="showUnrecommended?'显示推荐':'显示禁忌'"
                  dark
                  flat
                  hide-details
                  class="mt-0"
              />
            </v-card-title>
            <v-tabs
                v-model="curCategory"
                background-color="cyan darken-2"
                center-active
                show-arrows
                dark
                style="border-radius: 0"
            >
              <v-tab
                  v-for="pack in recommendedProducts"
                  :key="pack.category"
              >
                <v-icon v-if="pack.recommended.some(g =>g.data.length)">mdi-circle-medium</v-icon>
                {{ pack.categoryName }}
              </v-tab>

              <v-tab-item
                  v-for="pack in recommendedProducts"
                  :key="pack.category"
              >
                <div class="d-flex">
                  <v-tabs
                      color="cyan darken-3"
                      background-color="cyan lighten-5"
                      slider-size="4"
                      vertical
                  >
                    <v-tab
                        v-for="(group) in pack[showUnrecommended?'unRecommended':'recommended']"
                        :key="group.category"
                        style="width:128px"
                    >
                      <v-icon v-if="group.data.length">mdi-circle-medium</v-icon>
                      {{ group.category }}
                    </v-tab>

                    <v-tab-item
                        v-for="group in pack[showUnrecommended?'unRecommended':'recommended']"
                        :key="group.category"
                    >
                      <div class="d-flex flex-wrap py-4 px-4">
                        <product-card v-for="product in group.data"
                                      :key="product.id"
                                      :product="product"
                                      class="ma-2"
                                      @click.native="toggleProductSelect(product)"
                                      @changeSchedule="setProductSchedule($event, product)"
                                      @delete="product.selected&&toggleProductSelect(product)"
                        />
                      </div>
                    </v-tab-item>
                  </v-tabs>
                </div>
              </v-tab-item>
            </v-tabs>
            <v-card-text v-show="recommendedProducts.length===0">
              暂无产品信息
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- 用药提醒录入 -->
        <v-tab-item>
          <v-card width="960"
                  class="mb-4 pb-4"
          >
            <v-card-title class="pb-2">
              药品名称
              <v-spacer/>
            </v-card-title>
            <!-- 药品搜索 -->
            <v-card-text class="px-4">
              <v-autocomplete
                  :items="medicineItems"
                  :loading="isLoading"
                  :search-input.sync="medicineSearch"
                  no-filter
                  :auto-select-first="false"
                  :hide-selected="false"
                  no-data-text="未找到药品"
                  hide-details
                  item-text="name"
                  item-value="id"
                  label="药品搜索"
                  placeholder="输入关键字"
                  prepend-icon="mdi-database-search"
                  return-object
                  style="z-index: 10;width: 26em!important;flex-grow:0"
                  clearable
                  @keypress.enter="goSearchMedicines(medicineSearch)"
                  @change="selectMedicineFromSearch"
              ></v-autocomplete>
            </v-card-text>
            <v-card-text class="d-flex align-center">
              自定义药品:
              <v-text-field
                  v-model="customMedicineName"
                  dense
                  flat
                  outlined
                  hide-details
                  clearable
                  append-outer-icon="mdi-calendar-plus"
                  class="mt-0 ml-4 flex-grow-0"
                  style="width: 20em!important;"
                  @click:append-outer="addCustomMedicine"
              ></v-text-field>
            </v-card-text>
          </v-card>

          <!-- 分组分类产品展示 -->
          <v-card width="960" min-height="320">
            <v-card-title class="cyan darken-2 white--text">
              用药提醒
            </v-card-title>
            <div class="pa-4 d-flex flex-wrap">
              <medicine-card
                  v-for="m in medicinePlan"
                  :key="m.id"
                  :medicine="m"
                  class="ma-2"
                  @changeSchedule="setMedicineSchedule($event, m)"
                  @delete="removeMedicine(m)"
              />
            </div>
          </v-card>
        </v-tab-item>

        <!-- 健康日记录入 -->
        <v-tab-item>
          <v-card width="960" min-height="320">
            <v-card-title class="cyan darken-2 white--text">
              健康日记
              <v-spacer/>
              <v-btn dark icon @click="addFastScale(null)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <div class="pa-4">
              <fast-scale-editor v-if="editingScale"
                                 v-model="editingScale"
                                 :input-symptoms="healthItems.filter(s=>s.category==='symptom')"
                                 :health-plans="healthPlans"
                                 :last-record-time="record.recordFinishTime"
                                 @save="saveFastScale"
                                 @close="exitScaleEdit"
              />
            </div>
            <v-subheader>自定义</v-subheader>
            <div class="pa-4 d-flex flex-wrap">
              <diary-card
                  v-for="d in diaries.filter(e=>e.creatorId===$store.state.userInfo.operatorId)"
                  :key="d.id"
                  :diary="d"
                  edit-mode
                  class="mr-4 mb-4"
                  @changeSchedule="setDiarySchedule($event, d)"
                  @click.native="toggleDiarySelect(d)"
                  @delete="d.selected&&toggleDiarySelect(d)"
                  @editScale="editFastScale"
                  @deleteScale="deleteFastScale"
              />
            </div>
            <v-subheader>常用</v-subheader>
            <div class="pa-4 d-flex flex-wrap">
              <diary-card
                  v-for="d in diaries.filter(e=>e.common)"
                  :key="d.id"
                  :diary="d"
                  edit-mode
                  class="mr-4 mb-4"
                  @changeSchedule="setDiarySchedule($event, d)"
                  @click.native="toggleDiarySelect(d)"
                  @delete="d.selected&&toggleDiarySelect(d)"
                  @editScale="editFastScale"
                  @deleteScale="deleteFastScale"
              />
            </div>
            <v-subheader>其他</v-subheader>
            <div class="pa-4 d-flex flex-wrap">
              <diary-card
                  v-for="d in diaries.filter(e=>!e.common)"
                  :key="d.id"
                  :diary="d"
                  edit-mode
                  class="mr-4 mb-4"
                  @changeSchedule="setDiarySchedule($event, d.id)"
                  @click.native="toggleDiarySelect(d)"
                  @delete="d.selected&&toggleDiarySelect(d)"
                  @editScale="editFastScale"
                  @deleteScale="deleteFastScale"
              />
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <!-- 使用计划录入 -->
    <!-- TODO 关联商城连接 -->
    <!-- TODO 保存结果 -->
    <v-card width="400" class="ml-4 overflow-y-auto">
      <v-card-title>
        计划列表
        <v-spacer/>
        <v-btn outlined color="success" :loading="$store.state.loading" @click="savePlan">
          保存
        </v-btn>
        <v-btn :loading="isLoading" text color="primary" @click="showSchedule=true">
          预览
        </v-btn>
        <v-btn icon color="grey" @click="deleteAll">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-title>
      <div class="overflow-y-auto" :style="{height:`calc(100vh - ${marginTop} - 72px)`}">
        <template v-if="planTab===0">
          <template v-for="pc in productPlan">
            <v-card-subtitle class="pt-0 pb-2 font-weight-bold d-flex">
              {{ pc.categoryName }}
              <v-spacer/>
              <span class="grey--text darken-4">{{ pc.products.length }}</span>
            </v-card-subtitle>
            <product-card v-for="product in pc.products"
                          :key="product.id"
                          :product="product"
                          class="mx-auto mb-2"
                          @changeSchedule="setProductSchedule($event, product)"
                          @delete="product.selected&&toggleProductSelect(product)"
            />
            <v-card-text v-if="selectedProducts.filter(e => e.productCategory === pc.category).length===0">
              暂无计划
            </v-card-text>
          </template>
        </template>
        <template v-if="planTab===1">
          <v-card-subtitle class="pt-0 pb-2 font-weight-bold d-flex">
            当前用药
            <v-spacer/>
            <span class="grey--text darken-4">{{ medicinePlan.length }}</span>
          </v-card-subtitle>
          <template v-for="m in medicinePlan">
            <medicine-card
                :key="m.id"
                :medicine="m"
                class="mx-auto mb-2"
                @changeSchedule="setMedicineSchedule($event, m)"
                @delete="removeMedicine(m)"
            />
          </template>
          <v-card-text v-if="medicinePlan.length===0">
            当前暂无用药
          </v-card-text>
        </template>
        <template v-if="planTab===2">
          <v-card-subtitle class="pt-0 pb-2 font-weight-bold d-flex">
            健康日记
            <v-spacer/>
            <span class="grey--text darken-4">{{ diaryPlan.length }}</span>
          </v-card-subtitle>
          <template v-for="d in diaryPlan">
            <diary-card
                :key="d.id"
                :diary="d"
                class="mx-auto mb-2"
                @changeSchedule="setDiarySchedule($event, d)"
                @delete="d.selected&&toggleDiarySelect(d)"
            />
          </template>
          <v-card-text v-if="diaryPlan.length===0">
            当前暂无健康日记计划
          </v-card-text>
        </template>
      </div>
    </v-card>

    <v-dialog
        v-model="showSchedule"
        fullscreen
        eager
        hide-overlay
        transition="dialog-bottom-transition"
        style="z-index: 100!important;"
    >
      <v-card>
        <v-toolbar
            dense
            dark
            color="blue lighten-1"
            style="position: fixed; top:0; width:100%;z-index: 100"
        >
          <v-btn
              icon
              dark
              @click="showSchedule = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>计划预览</v-toolbar-title>
        </v-toolbar>
        <div style="height:48px"/>
        <week-schedule v-if="showSchedule"
                       :record-id="recordId"
                       :schedule="schedule"
                       @setProductSchedule="setProductSchedule(...$event)"
                       @toggleProductSelect="toggleProductSelect"
                       @setMedicineSchedule="setMedicineSchedule(...$event)"
                       @removeMedicine="removeMedicine"
                       @setDiarySchedule="setDiarySchedule(...$event)"
                       @toggleDiarySelect="toggleDiarySelect"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductCard from "./HealthPlanCard/ProductCard";
import Record from "./Record";
import MedicineCard from "./HealthPlanCard/MedicineCard";
import DiaryCard from "./HealthPlanCard/DiaryCard";
import WeekSchedule from "./WeekSchedule";
import {format} from 'silly-datetime'
import FastScaleEditor from "./HealthPlanCard/FastScaleEditor";

export default {
  name: "HealthPlan",
  components: {FastScaleEditor, WeekSchedule, DiaryCard, MedicineCard, Record, ProductCard},
  props: {
    recordId: String,
    marginTop: {
      type: String,
      default: '0px'
    },
    inputProductPlan: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    planTab: 0,
    productSearch: '',
    productItems: [],
    medicineSearch: '',
    medicineItems: [],
    isLoading: false,
    record: {},
    showHealthItems: true,
    categories: {
      'basicInfo': {name: '基础信息', active: true, max: 2, step: 0.5},
      'syndrome': {name: '证型', active: true, max: 5, step: 2},
      'medicine': {name: '药品', active: false, max: 5, step: 1},
      'disease': {name: '疾病', active: true, max: 2, step: 0.5},
      'symptom': {name: '症状', active: true, max: 3, step: 0.5},
      'syndromeType': {name: '证素', active: false, max: 21, step: 2},
      'treatment': {name: '治法', active: true, max: 2, step: 0.5},
    },
    healthItems: [],
    buffHealthItems: '',
    recommendedProducts: [],
    curCategory: 0,
    selectedProducts: [],
    showUnrecommended: false,
    productCategoryItems: [
      {category: 'aijiu', name: '艾灸配穴'},
      {category: 'recipe', name: '药膳食疗'},
      {category: 'product', name: '优选产品'},
      {category: 'food', name: '食材选择'},
    ],
    recommendCount: 5,
    productCategories: [],
    cautionCategories: ['food'],
    cautions: [],
    customMedicineName: '',
    medicinePlan: [],
    diaries: [],
    diaryPlan: [],
    showSchedule: false,
    // schedule: undefined,
    timeRanges: [
      {name: "清晨", start: 5, end: 7},
      {name: "上午", start: 7, end: 11},
      {name: "中午", start: 11, end: 13},
      {name: "下午", start: 13, end: 17},
      {name: "傍晚", start: 17, end: 19},
      {name: "夜间", start: 19, end: 23},
      {name: "发作时", start: 0, end: 0},
    ],
    startDate: '',
    futureDays: 7,
    editingScale: undefined,
  }),
  computed: {
    healthItemGroups() {
      const categories = this.categories
      return Object.entries(categories)
          .filter(c => c[1].active)
          .map(c => ({
            category: c[0],
            name: c[1].name,
            items: this.healthItems.filter(e => e.category === c[0])
          }))
          .filter(e => e.items.length)
    },

    /**
     * 前端处理, 成分过敏排查
     */
    cautionItems() {
      let materials = {}
      // console.log(this.selectedProducts)
      this.selectedProducts
          .filter(r => r.productCategory === 'recipe')
          .forEach(({id, info}) => {
            const m = info.find(i => i.field === '组成成分')?.value || []
            m.filter(e => e.material).forEach(e => {
              let i = materials[e.material]
              if (i) i.push(id)
              else materials[e.material] = [id]
            })
          })
      this.selectedProducts
          .filter(r => r.productCategory === 'product')
          .forEach(({id, info}) => {
            const m = info.find(i => i.field === '组成成分')?.value || []
            m.filter(e => e).forEach(e => {
              let i = materials[e]
              if (i) i.push(id)
              else materials[e] = [id]
            })
          })
      this.selectedProducts
          .filter(r => r.productCategory === 'food')
          .forEach(({id, name}) => {
            let i = materials[name]
            if (i) i.push(id)
            else materials[name] = [id]
          })
      return Object.entries(materials)
          .map(([k, v]) => ({
            item: k,
            ids: [...new Set(v)]
          }))
          .sort((a, b) => a.item.localeCompare(b.item))
    },
    productPlan() {
      return this.productCategoryItems.map(pc => ({
        category: pc.category,
        categoryName: pc.name,
        products: this.selectedProducts.filter(e => e.productCategory === pc.category)
      }))
    },
    healthPlans() {
      return {
        productPlan: this.productPlan,
        medicinePlan: this.medicinePlan,
        diaryPlan: this.diaryPlan
      }
    },
    schedule() {
      // console.log('schedule changed')
      return this.getSchedule()
    },
    servicePatternAction() {
      return this.$store.state.servicePatternAction
    }
  },
  watch: {
    planTab(v) {
      if (v === 0) this.$store.commit('setServicePatterns', ['产品推荐', '计划预览'])
      if (v === 1) this.$store.commit('setServicePatterns', ['用药提醒', '计划预览'])
      if (v === 2) this.$store.commit('setServicePatterns', ['健康日记', '计划预览'])
    },
    servicePatternAction(v) {
      const actionMap = {
        introduceAijiu: () => {
          this.planTab = 0
          this.curCategory = 0
        },
        introduceRecipies: () => {
          this.planTab = 0
          this.curCategory = 1
        },
        introduceProducts: () => {
          this.planTab = 0
          this.curCategory = 2
        },
        introduceFoods: () => {
          this.planTab = 0
          this.curCategory = 3
        },
        introduceFoodCautions: () => {
          this.planTab = 0
          this.curCategory = 3
          this.showUnrecommended = true
        },
      }
      if (actionMap[v]) actionMap[v]()
    },
    curCategory() {
      this.showUnrecommended = false
    },
    cautions: {
      deep: true,
      handler(v) {
        v.forEach(e => {
          e.ids.forEach(pid => {
            const p = this.selectedProducts.find(s => s.id === pid)
            this.toggleProductSelect(p)
          })
        })
      }
    },
    inputProductPlan: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        this.selectedProducts = []
        v.forEach(e => {
          this.selectedProducts.push(...e.products || [])
        })
        this.syncProductSelected()
      }
    },
    healthPlans: {
      deep: true,
      handler(v) {
        console.log('buffering health plans...')
        localStorage.setItem(this.recordId, JSON.stringify({plan: v, timestamp: new Date().valueOf()}))
      }
    },
    editingScale: {
      deep: true,
      handler(v) {
        // console.log('scale updated: ', v)
        if (!v) return
        if (!v.public) {
          this.$set(this.editingScale, 'customerId', this.$store.state.userInfo.customerId)
          console.log('scale updated: ', v)
        } else this.$delete(this.editingScale, 'customerId')
      }
    }
  },
  beforeCreate() {
    const shortUrl = this.$route.params.shortUrl || 'test'
    this.$store.commit('updateState', {key: 'token', value: shortUrl})
  },
  created() {
    this.$store.commit('setServicePatterns', ['产品推荐', '健康记录解读', '计划预览'])
  },
  beforeMount() {
    this.$store.dispatch('request', {
      action: 'checkRecordStatus',
      params: {
        recordId: this.recordId
      },
      triggerLoading: false,
      fn: data => {
        if (data.status !== 'diagnoseFinished') this.$nextTick(() => {
          this.$pop({
            text: '健康判断尚未确定, 请回上一步保存健康诊断',
            color: 'warning',
            top: true,
            timeout: 0
          })
        })
      }
    })
  },
  mounted() {
    if (this.recordId) {
      this.$store.dispatch('request', {
        action: 'getRenderedRecord',
        params: {
          recordId: this.recordId
        },
        triggerLoading: false,
        fn: data => {
          this.record = data
        }
      })
          .finally(() => this.$store.dispatch('request', {
            action: 'getRecordItems',
            params: {
              recordId: this.recordId
            },
            triggerLoading: false,
            fn: data => {
              this.healthItems = data
              this.buffHealthItems = JSON.stringify(data)
            }
          }))
      this.$store.dispatch('request', {
        action: 'getHealthPlans',
        params: {
          recordId: this.recordId,
          limit: 1
        },
        triggerLoading: false,
        fn: data => {
          // console.log(data)
          const plan = data[0]
          if (!plan) return
          plan.productPlan.forEach(e => {
            this.selectedProducts.push(...e.products)
          })
          this.medicinePlan.push(...(plan.medicinePlan || []))
          this.diaryPlan.push(...(plan.diaryPlan || []))
          this.syncProductSelected()
          this.syncDiarySelected()
        }
      })
    }
    this.getDiaries()
    const buff = JSON.parse(localStorage.getItem(this.recordId))
    if (!buff) return
    const {plan, timestamp} = buff
    if (new Date().valueOf() - timestamp > 24 * 3600 * 1000) localStorage.setItem(this.recordId, '""')
    else this.$confirm({
      title: '从缓存中恢复',
      text: '是否从缓存中恢复健康计划?'
    }).then(() => {
      this.selectedProducts = []
      plan.productPlan?.forEach(e => {
        this.selectedProducts.push(...e.products)
      })
      this.medicinePlan = plan.medicinePlan || []
      this.diaryPlan = plan.diaryPlan || []
    })
  }
  ,
  methods: {
    goSearchHealthItems(val) {
      if (this.isLoading || !val) return
      this.isLoading = true
      this.$store.dispatch('request', {
        action: 'searchHealthItems',
        params: {
          keywords: val,
        },
        triggerLoading: false,
        fn: (data) => {
          this.productItems = data.map(e => ({
            ...e,
            text: (e.priority ? '·' : '') + `${e.name} - ${this.categories[e.category].name}`
          }))
        }
      })
          .finally(() => this.isLoading = false)
    }
    ,

    goSearchMedicines(val) {
      if (this.isLoading || !val) return
      this.isLoading = true
      this.$store.dispatch('request', {
        action: 'searchMedicines',
        params: {
          keywords: val,
        },
        triggerLoading: false,
        fn: (data) => {
          this.medicineItems = data.map(e => ({...e, planCategory: 'medicine'}))
        }
      })
          .finally(() => this.isLoading = false)
    }
    ,

    getDiaries() {
      this.$store.dispatch('request', {
        action: 'getFastScales',
        params: {},
        triggerLoading: false,
        fn: (data) => {
          this.diaries = data.map(e => ({...e, planCategory: 'diary'}))
          this.syncDiarySelected()
        }
      })
    }
    ,

    selectHealthItemFromSearch(e) {
      if (!e) return
      let flag = false
      const idx = this.healthItems.findIndex(d => d.id === e.id)
      if (idx > -1) {
        this.$pop(e.name + '已经存在!')
        flag = true
      }
      if (!flag) this.healthItems.push(e)
    }
    ,

    selectMedicineFromSearch(e) {
      console.log(e)
      if (!e) return
      let flag = false
      const idx = this.medicinePlan.findIndex(d => d.id === e.id)
      if (idx > -1) {
        this.$pop(e.name + '已经存在!')
        flag = true
      }
      if (!flag) this.medicinePlan.push({...e, selected: true})
    }
    ,

    getItemValueTip(category, val) {
      const max = this.categories[category].max
      if (val > max * 0.75) return {color: 'red', text: '最优'}
      if (val > max * 0.38) return {color: 'warning', text: '优先'}
      return {color: 'primary', text: '兼顾'}
    }
    ,

    removeItem(id) {
      const idx = this.healthItems.findIndex(d => d.id === id)
      this.healthItems.splice(idx, 1)
    }
    ,

    syncProductSelected() {
      // 与计划列表同步
      this.recommendedProducts.forEach(r => {
        r.recommended.forEach(e => {
          e.data.forEach(p => {
            this.$set(p, 'selected', this.selectedProducts.some(s => s.id === p.id))
          })
        })
      })
    }
    ,

    recommendProducts() {
      if (this.productCategories.length === 0) {
        this.$pop({
          text: '请选择推荐类别',
          color: 'warning'
        })
        return
      }
      const _idx = a => this.productCategoryItems.findIndex(i => i.category === a.category)
      this.isLoading = true
      this.$store.dispatch('request', {
        action: 'recommendProductsByHealthItems',
        params: {
          healthItems: this.healthItems.filter(e => e.value),
          productCategories: this.productCategories
              .map(e => ({
                category: e.category,
                num: this.recommendCount
              }))
              .sort((a, b) => _idx(a) - _idx(b)),
        },
        triggerLoading: false,
        fn: (data) => {
          this.recommendedProducts = data
          /**
           * 增加planCategory属性
           */
          this.recommendedProducts.forEach(r => {
            r.recommended.forEach(e => {
              e.data.forEach(p => {
                this.$set(p, 'planCategory', 'product')
                if (this.selectedProducts.find(s => s.id === p.id)) this.$set(p, 'selected', true)
              })
            })
          })
          this.syncProductSelected()
        }
      })
          .finally(() => this.isLoading = false)
    }
    ,

    setProductSchedule(simpleSchedule, item) {
      // console.log('setProductSchedule')
      let product = this.recommendedProducts
          .find(r => r.category === item.productCategory)
          ?.recommended
          .find(r => r.category === item.category)
          ?.data
          .find(p => p.id === item.id)
      if (product) this.$set(product, 'simpleSchedule', simpleSchedule)
      product = this.selectedProducts.find(s => s.id === item.id)
      if (product) this.$set(product, 'simpleSchedule', simpleSchedule)
    }
    ,

    setMedicineSchedule(simpleSchedule, item) {
      const medicine = this.medicinePlan.find(e => e.id === item.id)
      this.$set(medicine, 'simpleSchedule', simpleSchedule)
    }
    ,

    setDiarySchedule(simpleSchedule, item) {
      let diary = this.diaries.find(p => p.id === item.id)
      if (diary) this.$set(diary, 'simpleSchedule', simpleSchedule)
      diary = this.diaryPlan.find(s => s.id === item.id)
      if (diary) this.$set(diary, 'simpleSchedule', simpleSchedule)
    }
    ,

    toggleProductSelect(item) {
      // if (this.showUnrecommended) return
      const idx = this.selectedProducts.findIndex(s => s.id === item.id)
      if (idx > -1) {
        this.selectedProducts.splice(idx, 1)
        // this.$set(p, 'selected', false)
      } else {
        this.selectedProducts.push(item)
        // this.$set(p, 'selected', true)
      }
      this.syncProductSelected()
    }
    ,

    syncDiarySelected() {
      this.diaries.forEach(d => {
        this.$set(d, 'selected', this.diaryPlan.some(e => e.id === d.id))
      })
    }
    ,

    toggleDiarySelect(item) {
      const idx = this.diaryPlan.findIndex(s => s.id === item.id)
      if (idx > -1) {
        this.diaryPlan.splice(idx, 1)
      } else {
        this.diaryPlan.push(item)
      }
      this.syncDiarySelected()
    }
    ,

    removeMedicine(item) {
      const idx = this.medicinePlan.findIndex(s => s.id === item.id)
      if (idx > -1) this.medicinePlan.splice(idx, 1)
    }
    ,

    addCustomMedicine() {
      if (!this.customMedicineName) return
      this.medicinePlan.push({
        id: 'custom_' + new Date().valueOf(),
        name: this.customMedicineName,
        category: 'medicine',
        planCategory: 'medicine',
        selected: true,
      })
      this.customMedicineName = ''
    }
    ,

    deleteAll() {
      this.$confirm({
        title: '清空所有计划?',
        text: '即将清除产品推荐、用药提醒与健康日记计划，是否继续？'
      }).then(() => {
        this.selectedProducts = []
        this.diaryPlan = []
        this.medicinePlan = []
        this.syncDiarySelected()
        this.syncProductSelected()
      }).catch(() => {
      })
    }
    ,

    addFastScale(scale) {
      // console.log(scale)
      this.editingScale = scale || {
        id: null,
        title: "",
        icon: "book-outline",
        creatorId: this.$store.state.userInfo.operatorId,
        customerId: '',
        updateTime: '',
        public: false,
        common: true,
        specificCustomer: false,
        items: [],
        default: {},
        cautions: [],
        optionCautions: [],
        renderModel: '',
        healthItems: []
      }
    }
    ,

    editFastScale(s) {
      if (s.public) {
        s = JSON.parse(JSON.stringify(s))
        s.id = null
        s.title += '(副本)'
        s.creatorId = this.$store.state.userInfo.operatorId
        s.public = false
        this.addFastScale(s)
      } else this.editingScale = s
    }
    ,

    exitScaleEdit() {
      this.$confirm({
        title: '确定关闭?',
        text: '确定放弃编辑?'
      })
          .then(() => {
            this.editingScale = undefined
          })
          .catch(() => {
          })
    }
    ,

    deleteFastScale(id) {
      if (this.diaries.find(d => d.id === id)?.public) {
        this.$pop({
          text: '公共问卷不可删除',
          color: 'error'
        })
      }
      this.$confirm({
        title: '确定删除?',
        text: '确定删除当前快速问卷?'
      })
          .then(() => {
            this.$store.dispatch('request', {
              action: 'deleteFastScales',
              params: {
                scaleIds: [id],
              },
              // triggerLoading: false,
              fn: data => {
                this.$pop('快速问卷删除成功!')
                if (this.editingScale && this.editingScale.id === id) this.editingScale = undefined
                let idx = this.diaries.findIndex(d => d.id === id)
                if (idx > -1) this.diaries.splice(idx, 1)
                idx = this.diaryPlan.findIndex(d => d.id === id)
                if (idx > -1) this.diaryPlan.splice(idx, 1)
              }
            })
          })
          .catch(() => {
          })
    }
    ,

    saveFastScale() {
      this.$store.dispatch('request', {
        action: 'saveFastScale',
        params: {
          scale: {...this.editingScale, updateTime: new Date().valueOf()},
        },
        // triggerLoading: false,
        fn: data => {
          this.$pop('快速问卷保存成功!')
          const idx = this.diaries.findIndex(d => d.id === this.editingScale.id)
          if (idx > -1) this.$set(this.diaries, idx, this.editingScale)
          else this.diaries.push(this.editingScale)
        }
      })
    }
    ,

    savePlan() {
      this.$emit('saveHealthPlans', this.healthPlans)
      this.$store.dispatch('request', {
        action: 'saveHealthPlans',
        params: {
          recordId: this.$store.state.userInfo.recordId,
          ...this.healthPlans
        },
        // triggerLoading: false,
        fn: data => {
          this.$pop('健康方案保存成功!')
          localStorage.setItem(this.recordId, '""')
        }
      })
    }
    ,

    getSchedule() {
      const today = this.startDate ? new Date(this.startDate).getDay() : new Date().getDay();
      const week = Array(this.futureDays)
          .fill(0)
          .map((_, i) => {
            const dateTs =
                (this.startDate ? new Date(this.startDate).valueOf() : new Date().valueOf()) +
                24 * 3600 * 1000 * i;
            return {
              day: "周" + "日一二三四五六".charAt((i + today) % 7),
              date: format(new Date(dateTs), 'YYYY-MM-DD'),
            };
          });
      const planItems = [];
      const plan = this.healthPlans
      plan.productPlan?.forEach((e) => {
        // TODO 前端处理,这是不是有冲突
        planItems.push(
            ...e.products.map((p) => ({...p, planCategory: "product"}))
        );
      });
      planItems.push(...(plan.medicinePlan || []));
      planItems.push(...(plan.diaryPlan || []));
      /**
       * 按照日期组装从当前日期开始futureDays数量
       */
      let timeRanges = this.timeRanges.map(e => e.name);
      let schedules = [];
      week.forEach((d) => {
        const items = planItems.filter(
            (p) =>
                !p?.simpleSchedule?.freq?.length ||
                p?.simpleSchedule?.freq.some((f) => f === d.day)
        );
        let schedule = timeRanges.map((t) => ({
          time: t,
          scheduleItems: items
              .filter((i) => i.simpleSchedule?.time.some((_t) => _t === t))
        }));
        schedule.push({
          time: "不拘时",
          scheduleItems: items
              .filter((i) => !i.simpleSchedule?.time?.length)
        });
        schedules.push({
          day: d.day,
          date: d.date,
          schedule,
        });
      });
      // console.dir(schedules, { depth: null });
      // console.log(planId);)
      return {schedules}
    }
  }
}
</script>

<style scoped>

</style>