<template>
  <div id="bodyPanel" ref="bodyPanel" style="position: relative; height: 100vh">
    <v-badge
        bordered
        color="error"
        :content="symptoms.length"
        :value="symptoms.length"
        overlap
        style="position:absolute; top: 96px; right: 24px; z-index: 10"
    >
      <v-btn
          class="white--text"
          color="error lighten-1"
          fab
          small
          right
          @click="step=3"
      >
        已选
      </v-btn>
    </v-badge>
    <v-stepper v-model="step"
               dark
               color="blue-grey darken-4"
               tile
               alt-labels
    >
      <v-stepper-header elecation="0">
        <v-stepper-step
            :complete="step>1"
            editable
            step="1"
        >
          <span class="step-font">躯体症状</span>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="step>2"
            editable
            step="2"
        >
          <span class="step-font">生命活动症状</span>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="step>3"
            step="3"
            editable
        >
          <span class="step-font">选择主诉</span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items style="height: 100%;">
        <v-stepper-content step="1">
          <v-card
              tile
              class="d-flex justify-center"
          >
            <body-selector :active-parts="activeParts"
                           :temp-parts="tempParts"
                           :height="`${bodyPanelHeight}px`"
                           :toggle="false"
                           @selectBodyPart="getBodyPart"/>
            <v-btn
                color="primary"
                fab
                absolute
                bottom
                right
                dark
                small
                @click="step=2"
                class="next"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2" style="height: 100%">
          <v-card :loading="loading"
                  :height="`${bodyPanelHeight}px`"
          >
            <v-tabs v-model="lifeInfoIndex"
                    vertical
                    slider-size="4"
            >
              <template v-for="item in lifeInfo">
                <v-tab>
                  {{ item.name }}
                </v-tab>
                <v-tab-item :transition="false" class="overflow-y-auto" :style="{height: `${bodyPanelHeight}px`}">
                  <v-list
                      dark
                      color="blue-grey darken-3"
                      shaped
                      class="pa-0 h-100p overflow-y-auto"
                  >
                    <v-list-item-group
                        multiple
                    >
                      <template v-for="(group, i) in item.subCategories">
                        <v-divider></v-divider>
                        <v-subheader @click="activeSubName=activeSubName===group.name?'':group.name">
                          <v-icon color="teal">mdi-circle-medium</v-icon>
                          {{ group.name }}
                          <v-spacer/>
                          <v-btn small outlined icon>
                            <v-icon>mdi-chevron-{{ activeSubName === group.name ? 'up' : 'down' }}</v-icon>
                          </v-btn>
                        </v-subheader>

                        <v-list-item
                            v-show="activeSubName===group.name"
                            v-for="s in group.symptoms"
                            :key="group.name+'-'+i+s.id"
                            @click="addLifeActionSymptom(s)"
                            active-class="warning--text text--accent-4"
                        >
                          <template v-slot:default="{ active}">
                            <v-list-item-content>
                              <v-list-item-title>{{ s.name }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                  :input-value="active"
                                  color="warning accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-tab-item>
              </template>
            </v-tabs>
            <v-btn
                color="primary"
                fab
                absolute
                bottom
                right
                dark
                small
                @click="step-=1"
                class="prev"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                color="primary"
                fab
                absolute
                bottom
                right
                dark
                small
                @click="step+=1"
                class="next"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card dark
                  color="blue-grey darken-3"
                  :loading="loading"
                  :height="`${bodyPanelHeight}px`">
            <v-subheader>病证查询</v-subheader>
            <v-autocomplete
                v-model="model"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                no-filter
                :auto-select-first="false"
                :hide-selected="false"
                hide-no-data
                hide-selected
                item-text="text"
                item-value="_id"
                label="关键字"
                placeholder="输入关键字"
                prepend-icon="mdi-database-search"
                return-object
                style="z-index: 10"
                class="px-4"
                @keypress.enter="goSearch(search)"
                @change="selectFromSearch($event)"
            ></v-autocomplete>

            <v-subheader>所有症状</v-subheader>
            <v-card-text>
              <v-chip v-for="(c, i) in symptoms"
                      :key="`${c.id}-${c.part}-${c.range}-symptom`"
                      v-if="c.category!=='disease'"
                      outlined
                      class="mr-2 mb-2"
                      close
                      :color="severities.find(e => e.value===(c.severity || 0)).color"
                      @click="updateSymptomSeverity(c)"
                      @click:close="symptoms.splice(i, 1)"
              >
                <v-icon small
                        left
                        color="error"
                        @click.stop="$emit('selectMajor', {...c, entityId: c.id})"
                >
                  mdi-clipboard-alert-outline
                </v-icon>
                {{ c.renderName || c.name }}{{ c.renderName ? `(${c.name})` : '' }}
              </v-chip>
            </v-card-text>
            <template v-if="symptoms.filter(e=>e.category==='disease').length">
              <v-subheader>所有疾病</v-subheader>
              <v-card-text>
                <v-chip v-for="(c, i) in symptoms"
                        :key="`${c.id}-disease`"
                        v-if="c.category==='disease'"
                        outlined
                        class="mr-2 mb-2"
                        close
                        @click:close="symptoms.splice(i, 1)"
                >
                  <v-icon small
                          left
                          color="error"
                          @click.stop="$emit('selectMajor', {...c, entityId: c.id})"
                  >
                    mdi-clipboard-alert-outline
                  </v-icon>
                  {{ c.name }}
                </v-chip>
              </v-card-text>
            </template>
            <v-btn
                color="primary"
                fab
                absolute
                bottom
                right
                dark
                small
                @click="step-=1"
                class="prev"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-bottom-sheet v-model="sheet"
                    internal-activator
                    attach="#bodyPanel"
    >
      <v-card dark
              color="blue-grey darken-4"
              :loading="loading"
              class="text-center pb-8"
              min-height="200"
      >
        <template>
          <div class="pt-4 px-4 text-start">
            当前部位：<span class="text-h6 warning--text">{{ activePartInfo.part }}</span>
          </div>

          <template v-if="activePartInfo.ranges&&activePartInfo.ranges.length">
            <v-divider class="mt-4"></v-divider>
            <v-subheader>
              上级范围
            </v-subheader>
            <v-chip-group column
                          active-class="success--text"
                          class="px-4"
            >
              <v-chip v-for="r in activePartInfo.ranges"
                      :key="'range-'+r"
                      v-if="r!==activePartInfo.part"
                      :value="r"
                      :filter="selectedRange === r"
                      outlined
                      @click="selectRange(r)"
              >
                {{ r }}
              </v-chip>
            </v-chip-group>
          </template>

          <template v-for="item in activePartInfo.abnormal" v-if="item.symptoms.length">
            <v-divider class="mt-4"></v-divider>
            <v-subheader>
              {{ item.name }}
            </v-subheader>
            <v-chip-group column
                          multiple
                          active-class="success--text"
                          class="px-4"
            >
              <v-chip v-for="s in item.symptoms"
                      :key="item.name+'-'+s.id"
                      :value="s.id"
                      outlined
                      @click="toggleSymptom(s)"
              >
                <v-icon
                    v-show="bodySymptomComplexIds.includes(`${s.id}.${activePartInfo.part}.${selectedRange}.${s.observeResultObject.text}`)">
                  mdi-check
                </v-icon>
                {{ s.observeResultObject.text }}
                {{
                  item.symptoms.filter(i => i.observeResultObject.text === s.observeResultObject.text).length > 1 ? `(${s.observeResultObject.tip})` : ''
                }}
              </v-chip>
            </v-chip-group>
          </template>
        </template>
        <v-btn
            fab
            small
            absolute
            top
            right
            @click="sheet=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import BodySelector from "@/components/BodySelector";
import {mapState} from "vuex";

export default {
  name: "SymptomSelector",
  components: {BodySelector},
  props: {
    inputEntities: {
      type: Array,
      default: function () {
        return []
      }
    },
    negativeEntities: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    bodyPanelHeight: 0,
    step: 1,
    sheet: false,
    activeParts: [],
    partInfo: [],
    lifeInfo: [],
    tempParts: [],
    activePartInfo: [],
    selectedRange: '',
    symptoms: [],
    bodySymptomComplexIds: [],
    lifeInfoIndex: 0,
    activeSubName: '',
    model: '',
    items: [],
    search: '',
    isLoading: false,
  }),
  computed: {
    ...mapState([
      "loading", "severities"
    ])
  },
  watch: {
    symptoms: {
      deep: true,
      handler(v) {
        if (!v) return
        let activeParts = {}
        v.forEach(({id, name, part, range, severity = 1}) => {
          /**
           * 1. 如果存在range, 则高亮range包含的parts
           * 2. 如果不存在range, 但存在part, 则高亮part
           * 3. 如果不存在range和part, 则高亮症状的相关range
           */
          if (range) this.partInfo.filter(p => p.ranges.includes(range)).forEach(({part: p}) => {
            activeParts[p] = activeParts[p] ? activeParts[p] + severity : severity
          })
          else if (part) activeParts[part] = activeParts[part] ? activeParts[part] + severity : severity
          else {
            let ranges = this.getRangesBySymptom({id, name})
            ranges = [...new Set(ranges)]
            ranges.forEach(range => {
              this.partInfo.filter(p => p.ranges.includes(range)).forEach(({part: p}) => {
                activeParts[p] = activeParts[p] ? activeParts[p] + severity * 0.2 : severity * 0.2
              })
            })
          }
        })
        this.activeParts = Object.entries(activeParts).map(e => ({name: e[0], val: e[1]}))
        // console.log('activeParts>>>', this.activeParts)
        this.$emit('update', v)
      }
    },
    inputEntities: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        this.symptoms = this.symptoms.concat(JSON.parse(JSON.stringify(v.filter(e => !this.symptoms.find(s => s.id === e.id)))))
      }
    },
    negativeEntityIds: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        this.symptoms = this.symptoms.filter(e => !v.includes(e.id))
      }
    },
    sheet(v) {
      if (!v) this.closePanel()
    }
  },
  created() {
    this.$store.dispatch('request', {
      action: 'getSymptomTree',
      params: {},
      // triggerLoading: false,
      fn: data => {
        this.partInfo = data.partInfo
        this.lifeInfo = data.lifeInfo
      }
    })
  },
  async mounted() {
    this.$nextTick(() => {
      this.bodyPanelHeight = this.$refs.bodyPanel.offsetHeight - 75
    })
  },
  methods: {
    goSearch(val) {
      // Items have already been requested
      if (this.isLoading || !val) return

      this.isLoading = true

      this.$store.dispatch('request', {
        action: 'searchEntities',
        params: {
          keywords: val,
        },
        triggerLoading: false,
        fn: (data) => {
          this.items = data
        }
      })
          .finally(() => this.isLoading = false)
    },

    getRangesBySymptom(symptom) {
      const {id, name} = symptom
      return this.partInfo.reduce((p, c) => {
        const {ranges, abnormal} = c
        if (abnormal.some(({symptoms}) => symptoms.find(s => (id && s.id === id) || (name && s.name === name)))) {
          /**
           * 仅选中范围的第一项, 因为范围是按照由子到父的顺序排列的
           */
          p.push(ranges[0])
        }
        return p
      }, [])
    },

    selectFromSearch(e) {
      if (!e) return
      if (this.symptoms.find(s => s.id === e.id)) this.$pop(e.name + '已经在病症列表中!', 'warning')
      else this.symptoms.push({...e, severity: 1})
    },

    updateSymptomSeverity(symptom) {
      if (symptom.category === 'disease') return
      let {severity = 0} = symptom
      let idx = this.severities.findIndex(e => e.value === severity)
      idx = (idx + 1) >= this.severities.length ? 0 : idx + 1
      this.$set(symptom, 'severity', this.severities[idx].value)
    },

    selectRange(r) {
      let parts = [this.activePartInfo.part]
      if (r) this.partInfo.filter(p => p.ranges.includes(r)).forEach(({part: p}) => {
        parts.push(p)
      })
      this.tempParts = parts
      this.selectedRange = r
    },

    getBodyPart(part) {
      this.bodySymptomComplexIds = this.symptoms
          .filter(s => s.observeResultObject)
          .map(s => (`${s.id}.${s.part}.${s.range}.${s.observeResultObject.text}`))
      console.log(this.bodySymptomComplexIds)
      this.sheet = true
      this.tempParts.push(part)
      this.activePartInfo = this.partInfo.find(p => p.part === part)
    },

    checkSameSymptomIdx(b) {
      return this.symptoms.findIndex(s => {
        return s.id === b.id
            && (s.range === this.selectedRange || s.range === b.range)
            && (s.part === this.activePartInfo.part || s.part === b.part)
            && s.observeResultObject === b.observeResultObject
      })
    },

    toggleSymptom(symptom) {
      // 取消症状选择
      const exitsIdx = this.checkSameSymptomIdx(symptom)
      if (exitsIdx > -1) {
        this.symptoms.splice(exitsIdx, 1)
        this.bodySymptomComplexIds = this.symptoms
            .filter(s => s.observeResultObject)
            .map(s => (`${s.id}.${s.part}.${s.range}.${s.observeResultObject.text}`))
        return
      }
      // 新增症状
      /**
       * 渲染症状名称
       * 如果observeResult=-1, 强制渲染
       * 1. 如果有range信息, 输出: range+observeResultObject
       * 2. 如果没有range信息, 但有part信息, 输出: part+observeResultObject
       * 2. 如果没有range信息或part信息, 输出: name
       */
      let renderName = ''
      const range = this.selectedRange
      const part = this.activePartInfo.part
      const observeResultObject = symptom.observeResultObject
      if (!observeResultObject) renderName = symptom.name;
      else if (observeResultObject.ignoreObserveObject) name = observeResultObject.text
      else if (range) renderName = range + observeResultObject.text;
      else if (part) renderName = part + observeResultObject.text;
      else renderName = symptom.name;
      this.symptoms.push({
        id: symptom.id,
        name: symptom.name,
        renderName,
        part,
        range,
        observeResultObject,
        severity: 1
      })
      console.log(this.symptoms)
      this.bodySymptomComplexIds = this.symptoms
          /**
           * 进过滤躯体类
           */
          .filter(s => s.observeResultObject)
          .map(s => (`${s.id}.${s.part}.${s.range}.${s.observeResultObject.text}`))
    },

    addLifeActionSymptom(s) {
      s = {...s, severity: 1}
      const idx = this.symptoms.findIndex(e => e.id === s.id)
      if (idx > -1) this.symptoms.splice(idx, 1)
      else this.symptoms.push(s)
    },

    closePanel() {
      console.log('closed')
      this.activePartInfo = {}
      this.selectedRange = ''
      this.tempParts = []
    },

  }
}
</script>

<style scoped>

.step-font {
  font-size: 14px;
  font-weight: lighter
}

.next, .prev {
  z-index: 100;
}

.next {
  margin-bottom: 24px;
}

.prev {
  margin-bottom: 80px;
}

>>> .v-stepper__step {
  padding: 12px 24px;
  flex-basis: unset !important;
}

>>> .v-stepper__header .v-divider {
  margin: unset !important;
}

>>> .v-stepper__content {
  padding: 0;
}

>>> .v-stepper__label {
  display: block !important;
}

</style>