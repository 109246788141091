<template>
  <v-card
      flat
      color="blue-grey lighten-2"
      class="overflow-y-auto"
  >
    <div v-for="(m, i) in parsedMessages"
         :key="i"
         :class="'message'">
      <!--      <v-avatar size="2em" color="blue" @dblclick="alterSpeaker(m)">-->
      <!--        <span class="white&#45;&#45;text">{{ m.specialist ? '我' : '客' }}</span>-->
      <!--      </v-avatar>-->
      <div class="bubble">
        <span v-for="(w, wi) in m.parsed"
             :key="`parsed-${wi}`"
             :class="['word', 'caption', {entity: w.entity}]"
             @click.stop="selectTextNode(w)"
        >{{ w.text }}</span>
      </div>
    </div>
    <div v-if="tempText" :class="['message']">
      <v-chip color="teal" small dark>讲话中</v-chip>
      <div class="bubble caption">{{ tempText }}</div>
    </div>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MsgBox",
  props: {
    tempText: String,
  },
  data: () => ({
    tempNodes: []
  }),
  computed: {
    ...mapState([
      "conversations"
    ]),
    parsedMessages() {
      // const len = this.conversations.length
      // console.log({conversationLength: len})
      return this.conversations.slice(-20)
    },
  },
  methods: {
    selectTextNode(node) {
      if (node.entity) this.$emit('selectEntity', {...node.entity, timestamp: new Date().valueOf()})
    }
  }
}
</script>

<style scoped>
.message {
  padding: 0.25em 1em;
  display: flex;
  align-items: center;
}

.message.self {
  flex-direction: row-reverse;
}

.bubble {
  margin: 0 0.5em;
  padding: 0.5em;
  background: white;
  border-radius: 0.2em;
  line-height: 1.2;
}

.word {
  /*display: inline-block;*/
  /*border-bottom: 0.5px solid #555;*/
  /*margin: 0 0.1em;*/
}

.entity, .baike {
  padding: 0 0.2em;
}

.entity {
  border-radius: 2px;
  background: #ffe6dd;
}

.baike {
  border: 0.5px solid #ff5421;
  border-radius: 2px;
}
</style>