<template>
  <div style="background:none">
    <v-expansion-panels v-model="activeSceneIndexes" focusable multiple>
      <v-expansion-panel
          v-for="(scene, sceneIdx) in curServicePatterns"
          :key="scene.id"
      >
        <v-expansion-panel-header hide-actions class="pt-2 pb-0">
          <template v-slot:default="{ open }">
            <div>
              <div class="subtitle-1">{{ scene.name }}</div>
              <v-chip-group v-model="activeCondition" column dark color="indigo" class="mt-n1">
                <v-chip
                    v-for="item in Array.from(new Set(scene.indexes.reduce((p,c)=>p.concat(c.items.map(i=>i.condition).filter(i=>i)),[])))"
                    :key="item"
                    small
                    filter
                    :value="item"
                    @click.stop=""
                >
                  {{ item }}
                </v-chip>
              </v-chip-group>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <template>
            <v-list two-line dense>
              <v-list-item-group
                  v-model="doneIndex[scene.id]"
                  active-class="blue-grey lighten-5 rounded"
              >
                <v-list-item v-for="index in scene.indexes"
                             :key="index.id"
                             class="px-0"
                             style="min-height: unset!important;align-items: unset"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mx-2" style="align-self: unset!important;">
                      <v-checkbox @click.stop=""></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <div :class="checkCondition(index).active?'red lighten-5 rounded px-1':''"
                           style="width: 100%!important;"
                           @click="call(index.default)"
                      >
                        <v-list-item-title
                            :class="['d-flex']">
                          {{ index.default.name }}
                          <v-spacer/>
                          <v-chip v-for="la in index.default.labels"
                                  :key="la"
                                  outlined
                                  color="indigo"
                                  small
                                  class="mr-1"
                                  @click.stop=""
                          >{{ la }}
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: normal;word-wrap: break-word">
                          {{ index.default.content }}
                        </v-list-item-subtitle>
                      </div>

                      <v-list v-show="(checkCondition(index, sceneIdx).active || active)&&index.items.length>1" dense
                              two-line
                              class="pl-2"
                              style="border-left: #00acc1 2px solid; width: 100%!important;">
                        <v-list-item-content v-for="chat in index.items"
                                             :key="chat.id"
                                             v-if="chat.id!==index.default.id"
                                             :class="['pb-0', checkCondition(index).ids.includes(chat.id)?'red lighten-5 rounded px-1':'']"
                                             @click.stop="call(chat)"
                        >
                          <v-list-item-title class="d-flex mb-0">
                            {{ chat.name }}
                            <v-spacer/>
                            <v-chip v-for="la in chat.labels"
                                    :key="la"
                                    dark
                                    color="indigo"
                                    small
                                    class="mr-1"
                                    @click.stop=""
                            >{{ la }}
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-subtitle style="white-space: normal;word-wrap: break-word">{{
                              chat.content
                            }}
                          </v-list-item-subtitle>
                          <v-divider class="mt-2"/>
                        </v-list-item-content>
                      </v-list>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ServicePattern",
  data: () => ({
    activeSceneIndexes: [0],
    doneIndex: {},
    activeItem: {},
    activeCondition: '',
  }),
  computed: {
    ...mapState([
      'servicePatterns', 'activeServicePatterns'
    ]),
    curServicePatterns() {
      const res = this.servicePatterns.filter(e => this.activeServicePatterns.includes(e.name))
      // this.activeSceneIndexes = Array(res.length).fill(0).map((_, i) => i)
      return res
    }
  },
  watch: {
    curServicePatterns: {
      immediate: true,
      handler(v) {
        if (!v) return
        this.$nextTick(() => {
          this.activeSceneIndexes = Array(v.length).fill(0).map((_, i) => i)
        })
      }
    },
    activeSceneIndexes: {
      deep: true,
      handler(v) {
      }
    }
  },
  methods: {
    getChats(scene, todo) {
      return scene.chat.filter(c => c.name === todo.name).length
    },
    checkCondition(idx, sceneIdx) {
      if (!this.activeCondition) return {active: false, ids: []}
      let active = false
      let ids = []
      idx.items.forEach(i => {
        if (i.condition === this.activeCondition) {
          active = true
          ids.push(i.id)
        }
      })
      if (active && !this.activeSceneIndexes.includes(sceneIdx)) this.activeSceneIndexes.push(sceneIdx)
      return {active, ids}
    },
    call(chat) {
      if (chat.action) this.$store.commit('updateState',{key: 'servicePatternAction', value: chat.action})
    }
  }
}
</script>

<style scoped>
>>> .v-expansion-panel-content__wrap {
  padding: 0 8px;
}
</style>