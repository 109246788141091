<template>
  <div>
    <v-text-field
        v-model="keywords"
        label="输入关键字"
        dark
        append-outer-icon="mdi-magnify"
        @click:append-outer="search(keywords)"
    ></v-text-field>

    <template v-if="tempResults.length">
      <div v-for="(items, i) in tempResults" :key="i">
        <baike-intro-card v-for="(item, i) in items.contents" :key="'tmp'+i" :baike="item"/>
      </div>
      <v-divider dark class="my-4"></v-divider>
    </template>

    <template v-for="(items, k) in baikeResults">
      <baike-intro-card v-for="(item, i) in items.contents" :key="'bic-'+k+'-'+i" :baike="item"/>
    </template>
  </div>
</template>

<script>
import BaikeIntroCard from "./BaikeIntroCard";
import {mapState} from "vuex";

export default {
  name: "BaikePanel",
  components: {BaikeIntroCard},
  props: {
    entities: {
      type: Object,
      default: function () {
        return {
          diseases: [],
          syndromes: [],
          symptoms: []
        }
      }
    }
  },
  data: () => ({
    keywords: '',
    baikeResults: [],
    tempResults: []
  }),
  computed: {
    ...mapState([
      'categoryColorMap'
    ])
  },
  watch: {
    entities: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!v) return
        const texts = Object.values((v)).flat()
        if (!texts.length) return
        this.search(texts.join(' '), true)
      }
    }
  },
  methods: {
    search(text, permanent) {
      const names = text.split(' ')
      this.$store.dispatch('request', {
        action: 'findBaike',
        params: {names: [...new Set(names)]},
        triggerLoading: false,
        fn: data => {
          if (permanent) this.baikeResults = data
          else this.tempResults = data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>