<template>
  <v-card v-if="medicine.id"
          width="375"
          :dark="medicine.selected"
          :color="medicine.selected?'light-blue darken-1':''"
  >
    <v-btn absolute icon top right class="mr-n2 mt-n2" @click.stop="$emit('delete')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="d-flex">
      <div class="pl-3 d-flex justify-center align-center">
        <v-avatar color="light-blue" size="56">
          <v-icon x-large>mdi-pill</v-icon>
        </v-avatar>
      </div>
      <div>
        <v-card-title>
          <div style="max-width: 320px">{{ medicine.name }}</div>
        </v-card-title>
        <v-card-text v-if="medicine.selected" :class="hideSchedule?'pt-0':'py-0'">
          <v-icon>mdi-{{ simpleSchedule.alarm ? 'alarm' : 'av-timer' }}</v-icon>
          {{ scheduleText }}
        </v-card-text>
      </div>
    </div>

    <v-card-actions v-if="!hideSchedule" class="pt-0">
      <v-spacer></v-spacer>
      <v-btn
          v-show="medicine.selected"
          icon
          @click.stop="showSchedule = !showSchedule"
      >
        <v-icon>{{ showSchedule ? 'mdi-clock-time-eight' : 'mdi-clock-time-eight-outline' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-if="medicine.selected&&!hideSchedule" v-show="showSchedule" @click.stop="">
        <v-divider></v-divider>

        <v-card-text>
          <div class="d-flex">
            频次
            <v-spacer/>
            <v-btn text @click="setFreq">
              {{ simpleSchedule.freq.length === 7 ? '清空' : '每天' }}
            </v-btn>
          </div>
          <v-chip-group
              v-model="simpleSchedule.freq"
              column
              multiple
          >
            <v-chip v-for="i in simpleScheduleItems.freq"
                    :key="i"
                    filter
                    outlined
                    dark
                    :value="i"
                    @click.stop=""
            >
              {{ i }}
            </v-chip>
          </v-chip-group>
          <div>时间</div>
          <v-chip-group
              v-model="simpleSchedule.time"
              column
              multiple
          >
            <v-chip v-for="i in simpleScheduleItems.time"
                    :key="i"
                    filter
                    outlined
                    dark
                    :value="i"
                    @click.stop=""
            >
              {{ i }}
            </v-chip>
          </v-chip-group>
          <div class="d-flex justify-space-between mt-2">
            <v-switch v-model="simpleSchedule.alarm"
                      dark
                      dense
                      flat
                      hide-details
                      class="mt-0"
                      :label="simpleSchedule.alarm?'取消提醒':'设置提醒'"
            />
            <v-btn
                v-show="medicine.selected"
                icon
                @click.stop="showSchedule = false"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>

  </v-card>
</template>

<script>
export default {
  name: "MedicineCard",
  props: {
    medicine: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hideSchedule: Boolean
  },
  created() {
    const today = new Date().getDay()
    const li = Array(7).fill(0).map((_, i) => {
      return '周' + "日一二三四五六".charAt((i + today) % 7)
    })
    this.$set(this.simpleScheduleItems, 'freq', li)
  },
  data: () => ({
    showInfo: false,
    showSchedule: false,
    simpleSchedule: {
      freq: [],
      time: [],
      alarm: false
    },
    simpleScheduleItems: {
      freq: [],
      time: ['清晨', '上午', '中午', '下午', '傍晚', '夜间', '发作时'],
    },
  }),
  computed: {
    scheduleText() {
      const {freq, time} = this.simpleSchedule
      let t = ''
      if (freq.length) t += (this.simpleSchedule.freq.length === 7 ? '每天' : freq.join('、')) + '，'
      else t += '遵照医嘱，'
      if (!time.length) t += '按照医生指导服用。'
      else t += time.join('、') + '服用。'
      return t
    }
  },
  watch: {
    'medicine.simpleSchedule': {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        this.simpleSchedule = v
      }
    },
    'medicine.selected': {
      handler(v) {
        if (v === false)
          this.simpleSchedule = {
            freq: [],
            time: [],
            alarm: false
          }
      }
    },
    scheduleText() {
      this.$emit('changeSchedule', this.simpleSchedule)
    }
  },
  methods: {
    setFreq() {
      if (this.simpleSchedule.freq.length === 7) this.$set(this.simpleSchedule, 'freq', [])
      else this.$set(this.simpleSchedule, 'freq', this.simpleScheduleItems.freq)
    }
  }
}
</script>

<style scoped>

</style>