<template>
  <div class="main-panel flex-grow-1 blue-grey overflow-y-auto">
    <v-card-title class="fixed blue-grey pl-0" style="z-index: 90;width: 100%">
      <v-btn @click="recognize">
        {{ !recording ? '开始' : '结束' }}
      </v-btn>
    </v-card-title>

    <v-card-title>对话内容</v-card-title>
    <msg-box :temp-text="tmpRes" class="msg-box" @selectEntity="$emit('selectEntity', $event)"/>
  </div>
</template>

<script>
import WebAudioSpeechRecognizer from '../utils/WebAudioSpeechRecognizer'
import MsgBox from "@/components/MsgBox";

let webAudioSpeechRecognizer
/** 获取签名 end */

export default {
  name: 'ConversationAssistant',
  components: {MsgBox},
  data: () => ({
    voicesData: [{
      "slice_type": 2,
      "index": 8,
      "start_time": 43180,
      "end_time": 44160,
      "voice_text_str": "排任务。",
      "word_size": 3,
      "word_list": [
        {
          "word": "排",
          "start_time": 43300,
          "end_time": 43510,
          "stable_flag": 1
        },
        {
          "word": "任务",
          "start_time": 43510,
          "end_time": 43900,
          "stable_flag": 1
        },
        {
          "word": "。",
          "start_time": 43510,
          "end_time": 43900,
          "stable_flag": 1
        }
      ],
      "voice_id": "d2dc35bf-287d-4e70-99c9-7d055a7670ac"
    }],
    params: {
      // 实时识别接口参数
      engine_model_type: '16k_zh', // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
      // 以下为非必填参数，可跟据业务自行修改
      voice_format: 1,
      // hotword_id : '08003a00000000000000000000000000',
      needvad: 1,
      vad_silence_time: 800,
      // filter_dirty: 1,
      // filter_modal: 2,
      // filter_punc: 0,
      convert_num_mode: 1,
      word_info: 2
    },
    signString: null,
    signCallbackString: null,
    recording: false,
    tmpRes: '',
    results: [],
    currentSpeaker: '',
    conversationStartTimestamp: -1,
  }),
  created() {
    this.getUrlAndSign()
  },
  methods: {
    async getUrlAndSign() {
      await this.$store.dispatch('request', {
        action: 'getUrlAndSign',
        params: this.params,
        triggerLoading: false,
        fn: data => {
          this.params.signString = data.signString
          this.params.signCallbackString = data.signCallbackString
        }
      })
    },
    async recognize() {
      // if (!this.recording && !this.signString || !this.signCallbackString) await this.getUrlAndSign()
      // 开始录音
      if (!this.recording) {
        webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(this.params);
        let resultText = '';
        webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
          // console.log('开始识别', res);
          this.conversationStartTimestamp = new Date().valueOf()
        };
        webAudioSpeechRecognizer.OnSentenceBegin = (res) => {
          // console.log('一句话开始', res);
        };
        webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
          // console.log('识别变化时', res);
          this.tmpRes = `${resultText}${res.voice_text_str}`
        }
        webAudioSpeechRecognizer.OnSentenceEnd = (res, audio) => {
          console.log('一句话结束', res);
          this.$store.dispatch('request', {
            action: 'analyzeSentence',
            params: {
              ...res,
              conversationStartTimestamp: this.conversationStartTimestamp,
              timestamp: new Date().valueOf()
            },
            fn: data => {
              this.tmpRes = ''
              // this.currentSpeaker = data.user
              const state = this.$store.state
              // 更新全局对话
              this.$store.commit('pushState', {
                property: state.conversations,
                value: data
              })
            }
          })
          this.$store.dispatch('request', {
            action: 'saveAudio',
            params: {
              ...res,
              audio,
              conversationStartTimestamp: this.conversationStartTimestamp,
              timestamp: new Date().valueOf()
            },
            fn: data => {
              console.log({audioSaved: data})
              // this.currentSpeaker = data.user
            }
          })

        };
        webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
          console.log('识别结束', res);
          this.tmpRes = ''
        };
        webAudioSpeechRecognizer.OnError = () => {
          // console.log('onErr/or', this.recording)
          this.recording = false
        };
        // 启动识别
        webAudioSpeechRecognizer.start();
      } else webAudioSpeechRecognizer.stop();
      this.recording = !this.recording
    }
  },
}
</script>

<style scoped>
.main-panel {
  box-sizing: border-box;
  /*position: fixed;*/
  /*right: 32px;*/
  /*top: 32px;*/
  min-width: 300px;
  height: 100%;
  overflow-y: auto;
  /*padding: 0 1em;*/
  /*display: flex;*/
}

.conversations {
  box-sizing: border-box;
  position: relative;
  width: 30%;
  height: 100%;
  margin-right: 1em;
  overflow-y: auto;
}

.entities {
  box-sizing: border-box;
  position: relative;
  width: 30%;
  height: 100%;
  padding: 1em;
  overflow-y: auto;
}

.msg-box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /*border-top: 0.5px solid #555;*/
}
</style>
