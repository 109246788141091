<template>
  <v-card v-if="product.id"
          width="375"
          :dark="product.selected"
          :color="product.selected?'cyan darken-1':''"
  >
    <v-btn absolute icon top right class="mr-n2 mt-n2" @click.stop="$emit('delete')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title>
      <div style="max-width: 320px" @click.stop="goto">{{ product.name }}</div>
    </v-card-title>
    <v-card-subtitle class="d-flex align-center">
      推荐度:
      <v-spacer/>
      <v-rating
          v-model="product.level"
          background-color="yellow accent-4"
          color="yellow accent-4"
          dense
          hover
          min="0"
          max="5"
          readonly
          size="20"
      ></v-rating>
    </v-card-subtitle>
    <v-card-text v-if="product.introduce" v-show="showInfo" class="pt-0">
      {{ product.introduce }}
    </v-card-text>
    <v-card-text v-if="product.suitable" class="pt-0">
      <v-icon :color="product.selected?'white':'success'" small>mdi-check</v-icon>
      {{ product.suitable }}
    </v-card-text>
    <v-card-text v-if="product.unsuitable" class="pt-0 red--text darken-2">
      <v-icon color="red" small>mdi-alert</v-icon>
      {{ product.unsuitable }}
    </v-card-text>
    <v-card-text v-if="product.selected" class="pt-0">
      <v-icon>mdi-{{ simpleSchedule.alarm ? 'alarm' : 'av-timer' }}</v-icon>
      {{ scheduleText }}
    </v-card-text>

    <v-card-actions class="pt-0">
      <v-btn text @click.stop="showInfo = !showInfo">{{ showInfo ? '收起' : '更多内容' }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
          v-if="!hideSchedule"
          v-show="product.selected"
          icon
          @click.stop="showSchedule = !showSchedule"
      >
        <v-icon>{{ showSchedule ? 'mdi-clock-time-eight' : 'mdi-clock-time-eight-outline' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="showInfo" @click.stop="">
        <v-divider></v-divider>

        <v-card-text>
          <template v-for="info in information" v-if="info.value && typeof info.value === 'string'">
            <div style="border-top:0.5px solid #00b8d4; border-left: 4px solid #00b8d4" class="pl-2 py-1 mb-1">
              {{ info.field }}
            </div>
            <div class="mb-4">{{ info.value }}</div>
          </template>
          <v-btn text block @click="showInfo=false">
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </v-card-text>
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <div v-if="product.selected&&!hideSchedule" v-show="showSchedule" @click.stop="">
        <v-divider></v-divider>

        <v-card-text>
          <div class="d-flex">
            频次
            <v-spacer/>
            <v-btn text @click="setFreq">
              {{simpleSchedule.freq.length===7?'清空':'每天'}}
            </v-btn>
          </div>
          <v-chip-group
              v-model="simpleSchedule.freq"
              column
              multiple
          >
            <v-chip v-for="i in simpleScheduleItems.freq"
                    :key="i"
                    filter
                    outlined
                    dark
                    :value="i"
                    @click.stop=""
            >
              {{ i }}
            </v-chip>
          </v-chip-group>
          <div>时间</div>
          <v-chip-group
              v-model="simpleSchedule.time"
              column
              multiple
          >
            <v-chip v-for="i in simpleScheduleItems.time"
                    :key="i"
                    filter
                    outlined
                    dark
                    :value="i"
                    @click.stop=""
            >
              {{ i }}
            </v-chip>
          </v-chip-group>
          <div class="d-flex justify-space-between mt-2">
            <v-switch v-model="simpleSchedule.alarm"
                      dark
                      dense
                      flat
                      hide-details
                      class="mt-0"
                      :label="simpleSchedule.alarm?'取消提醒':'设置提醒'"
            />
            <v-btn
                v-show="product.selected"
                icon
                @click.stop="showSchedule = false"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>

  </v-card>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hideSchedule: Boolean
  },
  created() {
    const today = new Date().getDay()
    const li = Array(7).fill(0).map((_, i) => {
      return '周' + "日一二三四五六".charAt((i + today) % 7)
    })
    this.$set(this.simpleScheduleItems, 'freq', li)
  },
  data: () => ({
    showInfo: false,
    showSchedule: false,
    simpleSchedule: {
      freq: [],
      time: [],
      alarm: false
    },
    simpleScheduleItems: {
      freq: [],
      time: ['清晨', '上午', '中午', '下午', '傍晚', '夜间', '不拘时'],
    },
  }),
  computed: {
    information() {
      const res = []
      const info = this.product.info
      const productCategory = this.product.productCategory
      if (productCategory === 'recipe') info.forEach(i => {
        if (i.field !== '组成成分') res.push(i)
      })
      else if (productCategory === 'product') info.forEach(i => {
        if (i.field !== '组成成分') res.push(i)
      })
      else info.forEach(i => res.push(i))
      return res
    },
    scheduleText() {
      const text = {
        aijiu: "操作",
        recipe: "食用",
        product: "使用或食用",
        food: "食用",
      }
      const {freq, time} = this.simpleSchedule
      let t = ''
      if (freq.length) t += (this.simpleSchedule.freq.length === 7 ? '每天' : freq.join('、')) + '，'
      else t += '不需要固定频率，'
      if (!time.length || time.includes('不拘时')) t += '也不用拘于特定时间。'
      else t += '最佳时间是在' + time.join('、') + text[this.product.productCategory]
      return t
    }
  },
  watch: {
    'product.simpleSchedule': {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        this.simpleSchedule = v
      }
    },
    'product.selected': {
      handler(v) {
        if (v === false)
          this.simpleSchedule = {
            freq: [],
            time: [],
            alarm: false
          }
      }
    },
    scheduleText() {
      this.$emit('changeSchedule', this.simpleSchedule)
    }
  },
  methods:{
    setFreq(){
      if (this.simpleSchedule.freq.length === 7) this.$set(this.simpleSchedule, 'freq', [])
      else this.$set(this.simpleSchedule, 'freq', this.simpleScheduleItems.freq)
    },

    goto() {
      if (this.product.productCategory === 'product') alert(`产品ID: ${this.product.id}`)
    }
  }
}
</script>

<style scoped>

</style>