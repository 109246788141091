<template>
  <v-card tile dark color="blue-grey darken-3">
    <v-card-title>
      健康情况
    </v-card-title>
    <v-card-text>
      <v-card-subtitle>
        主要问题
      </v-card-subtitle>
      <v-card-text>
        {{ record.hpi }}
        {{ record.historyChanges }}
      </v-card-text>
      <v-card-subtitle>
        刻下问题
      </v-card-subtitle>
      <v-card-text>
        {{ record.currentSymptoms }}
      </v-card-text>
      <v-card-subtitle>
        既往问题
      </v-card-subtitle>
      <v-card-text>
        {{ record.history }}
      </v-card-text>
    </v-card-text>
    <template v-if="!showDiagnoseOptions">
      <v-card-title class="pb-0">
        健康判断
      </v-card-title>
      <v-card-text v-if="record.diagnoses" class="d-flex justify-end">
        <div class="mr-2 font-weight-black">结论:</div>
        <div style="width: 33%" class="d-flex flex-column">
          <div v-for="(d, i) in record.diagnoses"
               :key="'d'+i"
               :class="{'font-weight-bold': !d.includes('疑似')}"
          >
            {{ d }}
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        您的健康专员正在为您进行健康判断, 请稍后查看~
      </v-card-text>
    </template>
    <v-divider/>

    <diagnose-selector v-show="showDiagnoseOptions"
                       :input-diagnoses="inputDiagnoses"
                       :diagnose-options="diagnoseOptions"
                       @update="$emit('update', $event)"/>

  </v-card>
</template>

<script>
import DiagnoseSelector from "../components/DiagnoseSelector";

export default {
  name: "Record",
  components: {DiagnoseSelector},
  props: {
    inputDiagnoses: Object,
    record: Object,
    diagnoseOptions: Array,
    showDiagnoseOptions: {type: Boolean, default: true}
  }
}
</script>

<style scoped>

</style>