import './cryptojs.js';

export default class SpeechRecognizer {
    constructor(params) {
        this.socket = null;
        this.isSignSuccess = false; // 是否鉴权成功
        this.isSentenceBegin = false; // 是否一句话开始
        this.query = params
        // 用户鉴权函数
        // this.signCallback = params.signCallback || null;
        this.isRecognizeComplete = false; // 当前是否识别结束
    }

    // 暂停识别，关闭连接
    stop() {
        if (this.socket && this.socket.readyState === 1) {
            this.socket.send(JSON.stringify({type: 'end'}));
        } else {
            this.OnError('连接未建立或连接已关闭');
            if (this.socket && this.socket.readyState === 1) {
                this.socket.close();
            }
        }
    }

    // 拼接鉴权数据
    getUrl() {
        const signStr = this.query.signString;
        const signCallbackString = this.query.signCallbackString
        return `${signStr}&signature=${encodeURIComponent(signCallbackString)}`;
    }

    // 建立websocket链接 data 为用户收集的音频数据
    async start() {
        const url = this.getUrl()
        console.log(url)
        if (!url) {
            this.OnError('鉴权失败')
            return
        }
        const self = this;
        if ('WebSocket' in window) {
            this.socket = new WebSocket(`wss://${url}`);
        } else if ('MozWebSocket' in window) {
            this.socket = new MozWebSocket(`wss://${url}`);
        } else {
            this.OnError('浏览器不支持WebSocket')
            return
        }
        this.socket.onopen = (e) => { // 连接建立时触发
        };
        this.socket.onmessage = (e) => { // 连接建立时触发
            const response = JSON.parse(e.data);
            if (response.code !== 0) {
                this.OnError(response.message);
                self.socket.close();
            } else {
                if (!this.isSignSuccess) {
                    this.OnRecognitionStart(response);
                    this.isSignSuccess = true;
                }
                if (response.final === 1) {
                    this.isRecognizeComplete = true;
                    this.OnRecognitionComplete(response);
                    return;
                }
                if (response.result) {
                    const res = {
                        ...response.result,
                        voice_id: response.voice_id
                    }
                    if (response.result.slice_type === 0) {
                        this.OnSentenceBegin(res);
                        this.isSentenceBegin = true;
                    } else if (response.result.slice_type === 2) {
                        if (!this.isSentenceBegin) {
                            this.OnSentenceBegin(res);
                        }
                        this.OnSentenceEnd(res);
                    } else {
                        this.OnRecognitionResultChange(res);
                    }
                }
            }
        };
        this.socket.onerror = (e) => { // 通信发生错误时触发
            this.socket.close();
            this.OnError(e);
        }
        this.socket.onclose = (event) => {
            if (!this.isRecognizeComplete) {
                this.OnError(event);
            }
        }
    }

    // 发送数据
    write(data) {
        if (!this.socket || this.socket.readyState !== 1) {
            this.OnError('连接未建立，请稍后发送数据！')
            return
        }
        this.socket.send(data);
    };

    // 开始识别的时候
    OnRecognitionStart(res) {

    }

    // 一句话开始的时候
    OnSentenceBegin(res) {

    }

    // 识别结果发生变化的时候
    OnRecognitionResultChange() {

    }

    // 一句话结束的时候
    OnSentenceEnd() {

    }

    // 识别结束的时候
    OnRecognitionComplete() {

    }

    // 识别失败
    OnError(e) {
        console.log(e)
    }
}
window && (window.SpeechRecognizer = SpeechRecognizer);