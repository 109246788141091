<template>
  <v-card
      :color="color"
      rounded
      elevation="6"
      class="mb-4"
  >
    <v-card-title>
      您有{{ group.name }}么?
      <v-spacer/>
      <v-btn :outlined="!group.escape"
             color="warning"
             depressed
             @click="toggleEscapeThisGroup"
      >
        没有{{ group.name }}的相关问题
      </v-btn>
    </v-card-title>

    <v-card-text class="d-flex flex-wrap">
      <v-btn v-for="symptom in group.symptoms"
             :key="symptom.symptomId"
             dark
             depressed
             :color="severities.find(e => e.value===(symptom.severity || 0)).color"
             class="mb-2 mr-2"
             @click="update(symptom)"
      >
        {{ symptom.name }}
        <v-icon small right @click.stop="activeSymptom=symptom">mdi-information</v-icon>
      </v-btn>
    </v-card-text>
    <!-- todo 附加信息需要考虑与那些症状关联, 略微复杂, 容后开发 -->
    <v-card-text v-if="activeSymptom" class="py-0">
      <v-text-field
          v-model="activeSymptom.text"
          outlined
          :label="activeSymptom.name"
          counter
          class="mt-0 pt-0"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SymptomItem",
  props: {
    color: String,
    group: Object
  },
  data: () => ({
    activeSymptom: null
  }),
  computed: {
    ...mapState([
      "severities"
    ])
  },
  methods: {
    update(symptom) {
      let {severity = 0} = symptom
      let idx = this.severities.findIndex(e => e.value === severity)
      idx = (idx + 1) >= this.severities.length ? 0 : idx + 1
      this.$set(symptom, 'severity', this.severities[idx].value)
      this.$set(symptom, 'timestamp', new Date().valueOf())
      this.$set(this.group, 'escape', false)
      this.$set(this.group, 'timestamp', new Date().valueOf())
    },
    toggleEscapeThisGroup() {
      const escape = this.group.escape
      if (escape) this.$set(this.group, 'escape', false)
      else {
        // this.$pop(`以后不再联想"${this.group.name}"问题`, 'primary')
        this.group.symptoms.forEach(e => this.$set(e, 'severity', 0))
        this.$set(this.group, 'escape', true)
      }
      // console.log(this.group.escape)
    }
  }
}
</script>

<style scoped>

</style>