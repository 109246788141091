<template>
  <v-card v-if="diary.id"
          width="375"
          :dark="diary.selected"
          :color="diary.selected?'teal lighten-1':''"
  >
    <v-btn absolute icon top right class="mr-n2 mt-n2" @click.capture="$emit('delete')">
      <v-icon @click.stop="">mdi-close</v-icon>
    </v-btn>
    <div class="d-flex">
      <div class="pl-3 d-flex justify-center align-center">
        <v-avatar :color="diary.selected?'teal':'white'" size="56">
          <v-icon :color="diary.selected?'white':'teal'" large>mdi-notebook-heart-outline</v-icon>
        </v-avatar>
      </div>
      <div>
        <v-card-title class="align-center">
          <div style="max-width: 320px">{{ diary.title }}</div>
          <template v-if="editMode">
            <v-btn icon small color="primary" @click.capture="$emit('editScale', diary)">
              <v-icon @click.stop="">mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-if="!diary.public" icon small color="grey" class="ml-1"
                   @click.capture="$emit('deleteScale', diary.id)">
              <v-icon @click.stop="">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-card-title>
        <v-card-text v-if="diary.selected" class="py-0">
          <v-icon>mdi-{{ simpleSchedule.alarm ? 'alarm' : 'av-timer' }}</v-icon>
          {{ scheduleText }}
        </v-card-text>
      </div>
    </div>

    <v-card-actions class="pt-0">
      <v-btn text @click.stop="showInfo = !showInfo">{{ showInfo ? '收起' : '查看内容' }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
          v-if="!hideSchedule"
          v-show="diary.selected"
          icon
          @click.capture="showSchedule = !showSchedule"
      >
        <v-icon @click.stop="">{{ showSchedule ? 'mdi-clock-time-eight' : 'mdi-clock-time-eight-outline' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="showInfo" @click.stop="">
        <v-divider></v-divider>
        <fast-scale-card :scale="diary" :title-bar="false" @update="updateDiary"/>
        <v-btn tile dark block color="blue-grey" @click="showInfo=false">
          <v-icon @click.stop="">mdi-chevron-up</v-icon>
        </v-btn>
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <div v-if="diary.selected&&!hideSchedule" v-show="showSchedule" @click.stop="">
        <v-divider></v-divider>

        <v-card-text>
          <div class="d-flex">
            频次
            <v-spacer/>
            <v-btn text @click="setFreq">
              {{ simpleSchedule.freq.length === 7 ? '清空' : '每天' }}
            </v-btn>
          </div>
          <v-chip-group
              v-model="simpleSchedule.freq"
              column
              multiple
          >
            <v-chip v-for="i in simpleScheduleItems.freq"
                    :key="i"
                    filter
                    outlined
                    dark
                    :value="i"
                    @click.stop=""
            >
              {{ i }}
            </v-chip>
          </v-chip-group>
          <div>时间</div>
          <v-chip-group
              v-model="simpleSchedule.time"
              column
              multiple
          >
            <v-chip v-for="i in simpleScheduleItems.time"
                    :key="i"
                    filter
                    outlined
                    dark
                    :value="i"
                    @click.stop=""
            >
              {{ i }}
            </v-chip>
          </v-chip-group>
          <div class="d-flex justify-space-between mt-2">
            <v-switch v-model="simpleSchedule.alarm"
                      dark
                      dense
                      flat
                      hide-details
                      class="mt-0"
                      :label="simpleSchedule.alarm?'取消提醒':'设置提醒'"
            />
            <v-btn
                v-show="diary.selected"
                icon
                @click.stop="showSchedule = false"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>

  </v-card>
</template>

<script>
import FastScaleCard from "./FastScaleCard";

export default {
  name: "DiaryCard",
  components: {FastScaleCard},
  props: {
    diary: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hideSchedule: Boolean,
    editMode: Boolean
  },
  created() {
    const today = new Date().getDay()
    const li = Array(7).fill(0).map((_, i) => {
      return '周' + "日一二三四五六".charAt((i + today) % 7)
    })
    this.$set(this.simpleScheduleItems, 'freq', li)
  },
  data: () => ({
    showInfo: false,
    showSchedule: false,
    simpleSchedule: {
      freq: [],
      time: [],
      alarm: false
    },
    simpleScheduleItems: {
      freq: [],
      time: ['清晨', '上午', '中午', '下午', '傍晚', '夜间', '发作时'],
    },
  }),
  computed: {
    scheduleText() {
      const {freq, time} = this.simpleSchedule
      let t = ''
      if (freq.length) t += (this.simpleSchedule.freq.length === 7 ? '每天' : freq.join('、')) + '，'
      else t += '按照健康专员安排，'
      if (!time.length) t += '随时记录。'
      else t += time.join('、') + '记录。'
      return t
    }
  },
  watch: {
    'diary.simpleSchedule': {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        this.simpleSchedule = v
      }
    },
    'diary.selected': {
      handler(v) {
        if (v === false)
          this.simpleSchedule = {
            freq: [],
            time: [],
            alarm: false
          }
      }
    },
    scheduleText() {
      this.$emit('changeSchedule', this.simpleSchedule)
    }
  },
  methods: {
    setFreq() {
      if (this.simpleSchedule.freq.length === 7) this.$set(this.simpleSchedule, 'freq', [])
      else this.$set(this.simpleSchedule, 'freq', this.simpleScheduleItems.freq)
    },
    updateDiary(e) {
      // console.log(e)
    }
  }
}
</script>

<style scoped>

</style>