<template>
  <v-card
      :color="color"
      :dark="dark"
      rounded
      elevation="6"
      class="mb-4"
  >
    <v-card-title>
      <span class="red--text">{{ answer.mandatory ? '*' : '' }}</span> {{ question.text }}
    </v-card-title>
    <v-card-subtitle>{{ tip }}</v-card-subtitle>

    <v-card-text v-if="answer.type.includes('num')" class="py-0">
      <v-text-field
          v-model="num"
          :label="numTip"
          outlined
          :rules="[value => answer.numRange[0] === answer.numRange[1] || Number(value) <= answer.numRange[1] && Number(value) >= answer.numRange[0] || `允许的数值范围: ${answer.numRange[0]} ~ ${answer.numRange[1]}`]"
          class="mt-0 pt-0"
          type="number"
      ></v-text-field>
    </v-card-text>

    <v-card-text v-if="answer.type==='num_unit'" class="pt-0">
      <v-btn-toggle
          v-model="unitId"
          light
          active-class="blue-grey darken-2"
          color="white"
          borderless
          class="w-100p overflow-x-auto blue-grey darken-2"
      >
        <v-btn v-for="u in answer.units"
               :key="u.unitId"
               :value="u.unitId">
          <v-icon v-if="unitId===u.unitId" color="white" small left>mdi-check</v-icon>
          {{ u.name }}
        </v-btn>
      </v-btn-toggle>
    </v-card-text>

    <v-card-text v-if="['single', 'multi'].includes(answer.type)" class="pt-0">
      <v-btn-toggle
          v-model="selectedIds"
          light
          active-class="blue-grey darken-2"
          color="white"
          borderless
          :multiple="answer.type==='multi'"
          class="w-100p overflow-x-auto blue-grey darken-2"
      >
        <v-btn v-for="option in answer.options"
               :key="option.optionId"
               :value="option.optionId">
          <v-icon v-if="selectedIds.includes(option.optionId)" color="white" small left>mdi-check</v-icon>
          {{ option.text }}
        </v-btn>
      </v-btn-toggle>
    </v-card-text>

    <v-card-text v-if="answer.textMaxLength" class="py-0">
      <v-text-field
          v-model="text"
          outlined
          :label="`描述${answer.textMaxLength > 0 ? (', ' + answer.textMaxLength + '字以内') : ''}`"
          counter
          :rules="[value => answer.textMaxLength < 0 || (value||'').length <= answer.textMaxLength || '超过字数限制']"
          class="mt-0 pt-0"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "QaItem",
  props: {
    inputValue: Object,
    color: String,
    dark: Boolean,
    question: {
      type: Object,
    },
    answer: {
      type: Object,
    }
  },
  data: () => ({
    tip: '',
    num: undefined,
    unitId: undefined,
    selectedIds: [],
    text: undefined,
    valid: true,
    timestamp: undefined,
  }),
  computed: {
    numTip() {
      if (!this.answer.type.includes('num')) return ''
      let tip = '请输入'
      const [min, max] = this.answer.numRange
      // const [min, max] = [0, 5]
      if (min === max) tip += ''
      else tip += `${min} ~ ${max}范围内的`
      if (this.answer.numType === 'integer') tip += '整数'
      return tip
    },
    unselectedIds() {
      const selectedIds = this.answer.type === 'single' ? (this.selectedIds.length ? [this.selectedIds] : []) : (this.selectedIds || [])
      if (!['single', 'multi'].includes(this.answer.type)) return undefined
      return this.answer.options.map(e => e.optionId).filter(e => !selectedIds.includes(e))
    },
    result() {
      const {num, unitId, text = ''} = this
      const selectedIds = this.answer.type === 'single' ? (this.selectedIds.length ? [this.selectedIds] : []) : this.selectedIds
      const unselectedIds = this.unselectedIds
      const textValid = this.answer.textMaxLength <= 0 || text.length <= this.answer.textMaxLength
      const numValid = (withUnit = false) => {
        let [min, max] = this.answer.numRange
        const _valid = num && min === max || Number(num) <= max && Number(num) >= min
        if (this.answer.mandatory) {
          if (!this.num || (withUnit && !this.unitId)) return false
          else return _valid
        } else {
          if (withUnit && (!this.unitId && !this.num) || (this.unitId && this.num)) return _valid
          else if (withUnit) return false
          else return _valid
        }
      }
      const optionValid = () => {
        if (this.answer.type === 'single') {
          if (!this.answer.mandatory) return true
          else return !!this.selectedIds.length;
        }
        let [min, max] = this.answer.optionRange
        const num = selectedIds?.length || 0
        if (this.answer.mandatory || this.selectedIds.length) return min === max || Number(num) <= max && Number(num) >= min
        else return true
      }
      // console.log(this.question.text, textValid, numValid(), optionValid())
      let result = {
        questionId: this.question.questionId,
        answerId: this.answer.answerId,
        type: this.answer.type,
        valid: textValid,
        text: text || '',
        timestamp: this.timestamp || new Date().valueOf()
      }
      this.timestamp = result.timestamp
      switch (this.answer.type) {
        case "text":
          this.valid = (this.answer.mandatory && !this.text) ? false : textValid
          return result
        case 'num':
          this.valid = numValid() && textValid
          return {
            ...result,
            num,
            valid: this.valid
          }
        case 'num_unit':
          this.valid = numValid(true) && textValid
          return {
            ...result,
            num,
            unitId,
            valid: this.valid
          }
        case 'single':
        case 'multi':
          this.valid = optionValid() && textValid
          return {
            ...result,
            selectedIds,
            unselectedIds,
            valid: this.valid
          }
        default:
          return result
      }
    },
    answerUpdate() {
      return this.answer.answerId + this.question.questionId
    }
  },
  watch: {
    inputValue: {
      immediate: true,
      handler(a, b) {
        // console.log(a, b)
        if (!a || JSON.stringify(a) === JSON.stringify(b)) return
        this.$nextTick(() => {
          const keys = ['num', 'unitId', 'selectedIds', 'text', 'timestamp']
          keys.forEach((k) => this[k] = a[k])
          if (this.answer.type === 'single' && a.selectedIds.length) this.selectedIds = a.selectedIds[0]
        })
      }
    },
    answerUpdate: {
      immediate: true,
      handler(v) {
        if (!v) return
        this.num = undefined
        this.unitId = undefined
        this.selectedIds = []
        this.text = undefined
        if (['num', 'num_unit'].includes(this.answer.type)) {
          let [min, max] = this.answer.numRange
          if (min !== 0 && max !== 0) this.tip = `请输入${min}~${max}的${this.answer.numType === 'integer' ? '整数' : '数字'}`
        }
        if (this.answer.type === 'multi') {
          let [min, max] = this.answer.optionRange
          if (min !== 0 && max !== 0) this.tip = `最少选择${min}项, 最多选择${max}项`
        }
      }
    },
    selected: {
      handler(v) {
        if (this.answer.type !== 'multi') return
        const [min, max] = this.answer.optionRange
        if (min === max) return
        if (v.length > max) {
          this.$pop(`最多可选${max}项`)
          this.selectedIds.pop()
        }
        // todo 保存时也得检查
        if (v.length < min) this.$pop(`最少选择${min}项`)
      }
    },

    result: {
      deep: true,
      handler(v) {
        this.$emit('update', v)
      }
    }
  },
}
</script>

<style scoped>
>>> .v-btn-toggle button {
  flex-grow: 1;
}
</style>