<template>
  <v-card dark
          tile
          :width="width"
          color="blue-grey darken-1">
    <v-card-title v-if="titleBar || editMode"
                  class="py-2 body-1 blue-grey darken-2"
                  @click="expand = !expand"
    >
      <v-icon v-if="icon" class="mr-2">mdi-{{ icon }}</v-icon>
      {{ title }}
      <v-spacer/>
      <v-btn
          icon
      >
        <v-icon>{{ expand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <v-card-text v-show="expand || !titleBar" class="pt-4 pb-2" :class="{'pt-6': !titleBar}">
        <template v-if="editMode">
          <draggable id="sure-group"
                     v-model="items"
                     :group="{name: 'group'}"
                     animation="300"
          >
            <div v-for="(q, k) in items" :key="k+q.id"
                 :class="['mb-4', editingItem.id===q.id?'blue-grey darken-4 pa-2 mx-n2 rounded':'']"
            >
              <div class="w-100 d-flex align-center flex-shrink-0">
                <v-icon v-show="!itemValid[q.id](self)" small class="mr-1">mdi-eye-off</v-icon>
                <div class="body-1 grey--text text--lighten-2">
                  <span class="font-weight-bold">{{ q.mandatory ? '*' : '' }}</span> {{ q.id }}: {{ q.text }}
                </div>
                <div class="ml-2">
                  {{ q.type === 'multi' ? '(可多选)' : '' }}
                </div>
                <v-divider class="ml-2"/>
                <v-btn icon small @click="activeEditingItem(q)">
                  <v-icon small>mdi-application-edit-outline</v-icon>
                </v-btn>
                <v-btn icon small @click="$emit('delete', q)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
              <v-text-field v-if="['text', 'number'].includes(q.type)"
                            v-model="self[q.id]"
                            :type="q.type"
                            dense
                            hide-details
                            class="mt-0"
              />
              <v-chip-group
                  v-if="['single', 'multi'].includes(q.type)"
                  v-model="self[q.id]"
                  column
                  :mandatory="q.mandatory"
                  :multiple="q.type==='multi'"
              >
                <v-chip v-for="a in q.options"
                        :key="a"
                        :value="a"
                >
                  {{ a }}
                  <v-menu
                      right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small
                              right
                              v-bind="attrs"
                              v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>

                    <v-list dense class="pa-0">
                      <v-list-item dense @click="setRelated('related',q.id, q.type, a)">
                        <v-list-item-title>关联选项</v-list-item-title>
                      </v-list-item>
                      <v-list-item dense @click="setRelated('caution',q.id, q.type, a)">
                        <v-list-item-title>关联预警</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-chip>
              </v-chip-group>
            </div>
          </draggable>
          <div v-if="!scale.items.length" class="white--text">{{ '当前问卷暂无题目' }}</div>
        </template>
        <template v-else>
          <div v-for="(q, k) in items" :key="k+q.id"
               v-if="itemValid[q.id](self)"
               :class="['mb-4', editingItem.id===q.id?'blue-grey darken-4 pa-2 mx-n2 rounded':'']"
          >
            <div class="w-100 d-flex align-center flex-shrink-0">
              <div class="body-1 grey--text text--lighten-2">
                <span class="font-weight-bold">{{ q.mandatory ? '*' : '' }}</span> {{ q.text }}
              </div>
              <div class="ml-2">
                {{ q.type === 'multi' ? '(可多选)' : '' }}
              </div>
              <v-divider class="ml-2"/>
            </div>
            <v-text-field v-if="['text', 'number'].includes(q.type)"
                          v-model="self[q.id]"
                          :type="q.type"
                          dense
                          hide-details
                          class="mt-0"
            />
            <v-chip-group
                v-if="['single', 'multi'].includes(q.type)"
                v-model="self[q.id]"
                column
                :mandatory="q.mandatory"
                :multiple="q.type==='multi'"
            >
              <v-chip v-for="a in q.options"
                      :key="a"
                      :value="a"
              >
                {{ a }}
              </v-chip>
            </v-chip-group>
          </div>
        </template>
      </v-card-text>
    </v-expand-transition>
    <v-btn v-if="titleBar" v-show="expand" fab absolute bottom right color="grey darken-2" small @click="expand=false">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "FastScaleCard",
  components: {draggable},
  props: {
    titleBar: {
      type: Boolean,
      default: true
    },
    scale: Object,
    editMode: Boolean,
    width: String | Number
  },
  data: () => ({
    title: null,
    icon: null,
    items: [],
    cautions: [],
    self: {},
    expand: false,
    editingItem: {},
    itemValid: {}
  }),
  computed: {},
  watch: {
    self: {
      deep: true,
      handler(v) {
        // console.log(v)
        // v.id = this.scale.id;
        this.$emit('update', {
          id: this.scale.id,
          title: this.scale.title,
          answer: this.self
        })
      }
    },
    scale: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!v) return
        // console.log('scale in card: ', v)
        if (this.editMode) this.expand = true
        this.title = v.title;
        this.icon = v.icon;
        this.items = v.items
        this.items.forEach(s => {
          let valid = s.valid
          let validFn = () => true
          if (valid?.length) validFn = this.parseValidFn(valid)
          this.itemValid[s.id] = validFn
        });
        this.cautions = v.cautions.map(e => eval(e))
        if (v.default) {
          this.self = v.default
        } else {
          let self = {};
          v.items.forEach(v => {
            self[v.id] = v.type === 'multi' ? [] : null
          });
          this.self = self
        }
      }
    }
  },
  mounted() {
    // if (this.defaultSelection) {
    //     this.self = this.defaultSelection
    // } else {
    //     let self = {};
    //     Object.entries(this.items).forEach(([k, v]) => {
    //         self[k] = v.multi ? [] : null
    //     })
    // }
  },
  methods: {
    activeEditingItem(q) {
      this.$emit('editing', q)
      this.editingItem = q
    },
    parseValidFn(valid) {
      return eval('(self)=>' + valid
          .map(v => {
            const {scaleItemId, scaleItemType, option} = v
            if (scaleItemType === 'single') return `self['${scaleItemId}']==='${option}'`
            else if (scaleItemType === 'multi') return `self['${scaleItemId}'].includes('${option}')`
          })
          .join('||'))
    },
    setRelated(mode, id, type, val) {
      // console.log({scaleItemId: id, option: val})
      // @setRelated="(editingItem.valid.find(v=>v.scaleItemId===$event.scaleItemId&&v.option===$event.option)||editingItem.valid.push($event))"
      if (mode === 'related') {
        const scaleItemId = id, scaleItemType = type, option = val
        if (scaleItemId !== this.editingItem.id && !this.editingItem.valid.find(v => v.scaleItemId === scaleItemId && v.option === option)) {
          this.editingItem.valid.push({scaleItemId, scaleItemType, option})
          this.itemValid[scaleItemId] = this.parseValidFn(this.editingItem.valid)
          console.log({scaleItemId, scaleItemType, option})
          console.log(this.itemValid)
        }
      }
      if (mode === 'caution') this.$emit('setOptionCautions', {scaleItemId: id, scaleItemType: type, option: val})
    },
  }
}
</script>

<style scoped>
>>> .v-slide-group__content span:not(.v-chip--active) span {
  color: #eee !important;
}

>>> .v-chip--active {
  background-color: #eee;
}

>>> .v-chip--active .v-chip__content {
  color: #111;
  font-weight: bold;
}
</style>