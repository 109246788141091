<template>
  <v-card dark color="blue-grey lighten-2" class="mb-2">
    <v-card-title class="body-1 pb-0" @click="show=!show">
      {{ baike.standardName }}<span class="ml-2 body-2 grey--text text--darken-2"></span>
      <v-spacer/>
      <v-chip
          dark
          small
          :color="categoryColorMap[baike.category==='症状'?'symptom':'disease']"
          style="opacity: .75"
      >
        {{ baike.category }}
      </v-chip>
    </v-card-title>
    <v-tabs
        v-model="currentItem"
        show-arrows
        background-color="blue-grey lighten-2"
    >
      <v-tab
          v-for="(item) in baike.contents"
          :key="item.title"
          :href="'#tab-'+tid+'-'+item.title"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentItem">
      <v-tab-item
          v-for="(item, t) in baike.contents"
          :key="item.title"
          :value="'tab-'+tid+'-'+item.title"
          color="blue-grey lighten-1"
      >
        <v-expand-transition>
          <v-card v-show="show" flat color="blue-grey lighten-5">
            <v-card-text>
              <div v-for="(li, i) in item.content" :key="i" v-if="valid(li)"
                   @click="selectPara(`${t}-${i}`)"
                   :class="selectedPara.includes(`${t}-${i}`)?'blue-grey darken-2 white--text pa-2 rounded':'pa-2'"
              >{{ li }}</div>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-tab-item>
    </v-tabs-items>
  </v-card>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BaikeIntroCard",
  props: {
    baike: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: () => ({
    show: false,
    currentItem: 0,
    tid: (Math.random() * 1000).toFixed(0),
    showCount: 3,
    selectedPara: [],
  }),
  computed: {
    ...mapState([
      "categoryColorMap"
    ]),
  },
  methods: {
    valid(t) {
      const esc = ['\n', '\t', '\r']
      return !esc.some(e => e === t.replace(' ', ''))
    },
    selectPara(id) {
      const idx = this.selectedPara.indexOf(id)
      if (idx > -1) this.selectedPara.splice(idx, 1)
      else this.selectedPara.push(id)
    }
  }
}
</script>

<style scoped>

</style>