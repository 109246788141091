import WebRecorder from "./WebRecorder.js";
import SpeechRecognizer from "./speechrecognizer.js";
import '../utils/cryptojs.js'
const lamejs = require('lamejs/lame.all.js')

const addWavHeader = function (samples, sampleRateTmp, sampleBits, channelCount) {
    let dataLength = samples.byteLength;
    let buffer = new ArrayBuffer(44 + dataLength);
    let view = new DataView(buffer);

    function writeString(view, offset, string) {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    let offset = 0;
    /* 资源交换文件标识符 */
    writeString(view, offset, 'RIFF');
    offset += 4;
    /* 下个地址开始到文件尾总字节数,即文件大小-8 */
    view.setUint32(offset, /*32*/ 36 + dataLength, true);
    offset += 4;
    /* WAV文件标志 */
    writeString(view, offset, 'WAVE');
    offset += 4;
    /* 波形格式标志 */
    writeString(view, offset, 'fmt ');
    offset += 4;
    /* 过滤字节,一般为 0x10 = 16 */
    view.setUint32(offset, 16, true);
    offset += 4;
    /* 格式类别 (PCM形式采样数据) */
    view.setUint16(offset, 1, true);
    offset += 2;
    /* 通道数 */
    view.setUint16(offset, channelCount, true);
    offset += 2;
    /* 采样率,每秒样本数,表示每个通道的播放速度 */
    view.setUint32(offset, sampleRateTmp, true);
    offset += 4;
    /* 波形数据传输率 (每秒平均字节数) 通道数×每秒数据位数×每样本数据位/8 */
    view.setUint32(offset, sampleRateTmp * channelCount * (sampleBits / 8), true);
    offset += 4;
    /* 快数据调整数 采样一次占用字节数 通道数×每样本的数据位数/8 */
    view.setUint16(offset, channelCount * (sampleBits / 8), true);
    offset += 2;
    /* 每样本数据位数 */
    view.setUint16(offset, sampleBits, true);
    offset += 2;
    /* 数据标识符 */
    writeString(view, offset, 'data');
    offset += 4;
    /* 采样数据总数,即数据总大小-44 */
    view.setUint32(offset, dataLength, true);
    offset += 4;

    function floatTo32BitPCM(output, offset, input) {
        input = new Int32Array(input);
        for (let i = 0; i < input.length; i++, offset += 4) {
            output.setInt32(offset, input[i], true);
        }
    }

    function floatTo16BitPCM(output, offset, input) {
        input = new Int16Array(input);
        for (let i = 0; i < input.length; i++, offset += 2) {
            output.setInt16(offset, input[i], true);
        }
        return input
    }

    function floatTo8BitPCM(output, offset, input) {
        input = new Int8Array(input);
        for (let i = 0; i < input.length; i++, offset++) {
            output.setInt8(offset, input[i], true);
        }
    }

    if (sampleBits === 16) {
        floatTo16BitPCM(view, 44, samples);
    } else if (sampleBits === 8) {
        floatTo8BitPCM(view, 44, samples);
    } else {
        floatTo32BitPCM(view, 44, samples);
    }
    return view;
}

function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
            reject(new Error('blobToBase64 error'));
        };
    });
}

const convertToMp3 = async (samples) => {
    // console.log(samples)
    let buffer = [];
    // console.log(lamejs.Mp3Encoder)
    let mp3enc = new lamejs.Mp3Encoder(1, 16000, 32);
    let remaining = samples.length;
    let maxSamples = 1152;
    for (let i = 30; remaining >= maxSamples; i += maxSamples) {
        let mono = samples.subarray(i, i + maxSamples);
        // console.log(mono)
        let mp3buf = mp3enc.encodeBuffer(mono);
        if (mp3buf.length > 0) {
            buffer.push(new Int8Array(mp3buf));
        }
        remaining -= maxSamples;
    }
    let d = mp3enc.flush();
    if(d.length > 0){
        buffer.push(new Int8Array(d));
    }

    console.log('done encoding: ', buffer.length);
    let blob = new Blob(buffer, {type: 'audio/mp3'});
    // let bUrl = window.URL.createObjectURL(blob);
    // console.log('Blob created:', blob);
    return await blobToBase64(blob)
}


export default class WebAudioSpeechRecognizer {
    constructor(params) {
        this.recorder = null;
        this.speechRecognizer = null;
        this.isCanSendData = false;
        this.audioData = [];
        this.timer = null;
        this.audioBuff = []
        this.buffOffset = 0
        this.params = params
    }

    start() {
        this.recorder = new WebRecorder();
        this.recorder.OnReceivedData = (res) => {
            this.audioData.push(...new Int8Array(res));
            this.audioBuff.push(...new Int8Array(res))
            const engineModelType = this.params['engine_model_type'].includes('8k') ? 640 : 1280;
            if (this.timer) {
                return false;
            }
            if (this.isCanSendData) {
                let data = this.audioData.splice(0, engineModelType);
                let audioData = new Int8Array(data)
                this.speechRecognizer.write(audioData);
                // 发送数据
                this.timer = setInterval(() => {
                    if (this.isCanSendData) {
                        data = this.audioData.splice(0, engineModelType);
                        audioData = new Int8Array(data)
                        this.speechRecognizer.write(audioData);
                    }
                }, 40)
            }
        };
        // 录音失败时
        this.recorder.OnError = (err) => {
            alert(err)
            this.stop();
            this.OnError(err);
        }
        this.recorder.start();
        if (!this.speechRecognizer) {
            this.speechRecognizer = new SpeechRecognizer(this.params);
        }
        // 开始识别
        this.speechRecognizer.OnRecognitionStart = (res) => {
            this.OnRecognitionStart(res);
            this.isCanSendData = true;
        };
        // 一句话开始
        this.speechRecognizer.OnSentenceBegin = (res) => {
            this.OnSentenceBegin(res);
        };
        // 识别变化时
        this.speechRecognizer.OnRecognitionResultChange = (res) => {
            this.OnRecognitionResultChange(res);
        };
        // 一句话结束
        this.speechRecognizer.OnSentenceEnd = async (res) => {
            let {start_time, end_time} = res
            const _buff = end_time
            start_time -= this.buffOffset
            end_time -= this.buffOffset
            const arrayBuff = this.audioBuff.slice(start_time * 16 * 2, end_time * 16 * 2)
            // console.log(arrayBuff)
            const wav = addWavHeader(new Int8Array(arrayBuff).buffer, 16000, 16, 1)
            this.OnSentenceEnd(res, await convertToMp3(new Int16Array(wav.buffer)))
            // this.OnSentenceEnd(res, arrayBufferToBase64(wav.buffer))
            this.buffOffset = _buff
            this.audioBuff.splice(0, end_time * 16 * 2)
        };
        // 识别结束
        this.speechRecognizer.OnRecognitionComplete = (res) => {
            this.OnRecognitionComplete(res);
            this.isCanSendData = false;
        };
        // 识别错误
        this.speechRecognizer.OnError = (res) => {
            this.recorder.stop();
            console.log('error', res)
            this.isCanSendData = false;
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.OnError(res);
        };
        // 建立连接
        this.speechRecognizer.start();
    }

    stop() {
        if (this.recorder) {
            this.recorder.stop();
        }
        if (this.speechRecognizer) {
            this.speechRecognizer.stop();
        }
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    // 开始识别的时候
    OnRecognitionStart(res) {

    }

    // 一句话开始的时候
    OnSentenceBegin(res) {

    }

    // 识别结果发生变化的时候
    OnRecognitionResultChange() {

    }

    // 一句话结束的时候
    OnSentenceEnd() {

    }

    // 识别结束的时候
    OnRecognitionComplete() {

    }

    // 识别失败
    OnError(res) {
    }
}
window && (window.WebAudioSpeechRecognizer = WebAudioSpeechRecognizer);