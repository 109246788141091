<template>
  <div style="border-left: 2px solid olivedrab">
    <div class="d-flex align-center">
      <div style="width: 18px" class="text-right mr-1">{{ index }}</div>
      <v-checkbox v-if="type==='reminder'"
                  v-model="done"
                  dense
                  hide-details
                  class="shrink pt-0 mr-0 mt-0"
      ></v-checkbox>
      <v-icon v-else size="18" style="margin: 0 3px 0 3px">mdi-calendar-text</v-icon>
      <div>
        <span v-for="i in level" :key="i" class="red--text font-weight-bold">!</span>
        <v-icon v-show="alarmTimes.length" color="warning">mdi-alarm</v-icon>
      </div>
      <v-text-field v-model="name"
                    placeholder="添加项目"
                    hide-details
                    class="pt-0 mt-0"
                    append-icon="mdi-information-outline"
                    @focus="setNameFocus"
                    @blur="setNameBlur"
                    @click:append="showMore=!showMore"
      />
    </div>
    <div class="d-flex">
      <div style="width:24px" class="mr-1"/>
      <v-textarea v-show="showContent"
                  v-model="content"
                  placeholder="添加备注"
                  :outlined="contentFocus"
                  hide-details
                  rows="1"
                  auto-grow
                  class="pt-0"
                  @focus="contentFocus=true"
                  @blur="setContentBlur"
      />
    </div>

    <v-expand-transition>
      <div v-show="showMore">
        <div class="d-flex">
          <div style="width:24px" class="mr-1"/>
          <div class="flex-grow-1">
            <div class="mt-1">重要程度</div>
            <v-rating v-model="level">
              <template v-slot:item="props">
                <v-icon
                    :color="props.isFilled ? 'red' : 'grey lighten-1'"
                    @click="props.click"
                >
                  {{ props.isFilled ? 'mdi-alert-circle' : 'mdi-alert-circle-outline' }}
                </v-icon>
              </template>
            </v-rating>

            <div class="mt-1 mb-2 d-flex">
              <span class="mr-4">提醒: </span>
              <v-switch :value="type==='reminder'"
                        dense
                        hide-details
                        color="red"
                        class="mt-0 pt-0"
                        @change="type=type==='reminder'?'note':'reminder'"
              />
            </div>

            <v-expand-transition>
              <div v-show="type==='reminder'" class="mt-1">
                <div>提醒时间</div>
                <v-chip-group
                    v-model="alarmTimes"
                    column
                    multiple
                    color="red"
                    active-class="red"
                >
                  <v-chip v-for="i in timeRanges"
                          :key="i"
                          filter
                          outlined
                          small
                          :value="i"
                  >
                    {{ i }}
                  </v-chip>
                </v-chip-group>
              </div>
            </v-expand-transition>

            <div class="mt-1">关联事项</div>
            <v-autocomplete
                v-model="related"
                :items="relatedItems"
                dense
                filled
                chips
                color="blue-grey lighten-2"
                label="选择关联事项"
                return-object
                multiple
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    small
                    close
                    class="mb-1"
                    @click="data.select"
                    @click:close="remove(data.item)"
                >
                  <v-avatar left>
                    <v-icon small>{{ data.item.icon }}</v-icon>
                  </v-avatar>
                  ({{ data.item.time }}){{ data.item.text }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-icon>{{ data.item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.text }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                      v-html="`${planCategoryName[data.item.planCategory]} - ${data.item.time}`"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-btn text block @click="showMore=false">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import {customAlphabet} from 'nanoid'

const nanoid = customAlphabet('1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM', 10)

export default {
  name: "WorkLogItem",
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          id: nanoid(10),
          index: -1,
          name: '',
          content: '',
          type: 'note',
          done: false,
          level: 0,
          date: '',
          alarmTimes: []
        }
      }
    },
    index: Number,
    schedules: Object,
  },
  data: () => ({
    nameFocus: false,
    contentFocus: false,
    showContent: false,
    showMore: false,
    done: false,
    id: nanoid(10),
    type: 'note',
    name: '',
    content: '',
    level: 0,
    date: '',
    alarmTimes: [],
    timeRanges: ['清晨', '上午', '中午', '下午', '傍晚'],
    related: [],
    planCategoryName: {
      medicine: '用药提醒',
      product: '健康产品/方案',
      diary: '健康日记',
    }
  }),
  computed: {
    log() {
      return {
        id: this.id,
        index: this.index,
        name: this.name,
        content: this.content,
        type: this.type,
        done: this.done,
        level: this.level,
        alarmTimes: this.alarmTimes,
        date: this.date,
        // TODO 重复处理
        related: this.related.map(e => ({
          workLogId: this.id,
          itemId: e.id,
          date: e.date,
          time: e.time,
        })).filter(e => e.itemId)
      }
    },
    relatedItems() {
      let items = []
      const icons = {
        medicine: 'mdi-pill',
        product: 'mdi-format-list-checks',
        diary: 'mdi-notebook-heart-outline',
      }
      if (!this.schedules) return []
      const date = this.schedules.date
      this.schedules.schedule.forEach(timeRange => {
        const time = timeRange.time
        if (!timeRange.scheduleItems.length) return
        items.push({
          divider: true
        })
        items.push({
          header: time
        })
        timeRange.scheduleItems?.forEach(item => {
          const name = item.name || item.title
          items.push({
            id: item.id,
            name,
            planCategory: item.planCategory,
            date,
            time,
            icon: icons[item.planCategory],
            text: name.length > 12 ? `${name.slice(0, 12)}...` : name,
            value: `${date}-${time}-${item.id}`
          })
        })
      })
      return items
    }
  },
  watch: {
    type(v) {
      if (v === 'note') this.alarmTimes = []
    },
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v || JSON.stringify(v) === JSON.stringify((this.log))) return
        if (!v.id) v.id = nanoid(10)
        Object.entries(v).forEach(([k, val]) => this[k] = val)
        this.$nextTick(() => {
          this.related = (v.related || [])
              .map(e => ({
                id: e.itemId,
                date: e.date,
                time: e.time,
                value: `${e.date}-${e.time}-${e.itemId}`
              }))
              .filter(e => e.id)
        })
      }
    },
    log: {
      deep: true,
      handler(v) {
        if (!v) return
        this.$emit('input', v)
        this.$emit('change', v)
      }
    },
    relatedItems: {
      deep: true,
      handler(){
        if (!this.relatedItems?.length) return
        this.related = this.related.filter(r => this.relatedItems.find(i => i.id === r.id))
      }
    },
    related: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return
        // console.log(v)
        this.$emit('updateRelated', {
          date: this.date,
          items: this.related.map(e => ({
            workLogId: this.id,
            itemId: e.id,
            date: e.date,
            time: e.time,
          })).filter(e => e.itemId)
        })
      }
    }
  },
  methods: {
    setNameFocus() {
      this.nameFocus = true
      this.showContent = true
    },
    setNameBlur() {
      setTimeout(() => {
        this.showContent = !!this.content || this.contentFocus
      }, 300)
    },
    setContentBlur() {
      this.contentFocus = false
      setTimeout(() => {
        this.showContent = !!this.content
      }, 300)
    },
    remove(item) {
      const index = this.related.findIndex(e => e.value === item.value)
      if (index >= 0) this.related.splice(index, 1)
    },
  }
}
</script>

<style scoped>
>>> .v-input--selection-controls__input {
  margin-right: 0;
}

>>> .v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
</style>